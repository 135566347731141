import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAxios } from '../../hooks';
import GameWhoWantScoreTable from './GameWhoWantScoreTable';
import { Page } from '../../components';

const GameWhoWantScore = () => {
	const [showEdit, setShowEdit] = useState(false);
	const navigate = useNavigate();

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: '/game/admin/wwsList?page=0&size=9999',
		});
	};

	useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	useEffect(() => {
		getGames();
	}, []);

	useEffect(() => {
		if (api.saveGame.response) {
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	return (
		<Page>
			<Page.Header header="Oyunlar" title="Soru Çarkı">
				<Page.Header.Add
					show
					onClick={() =>
						navigate(
							`/edit-who-want-score/${api.getGames.response?.data?.content?.length}`
						)
					}
				/>
			</Page.Header>
			<Page.Body
				loading={api.getGames.loading || api.getBrandStyles.loading}
				show={!api.getGames.loading && api.getGames.response}
			>
				<GameWhoWantScoreTable
					saveGame={() => setShowEdit(true)}
					api={api}
					setShowEdit={setShowEdit}
				/>
			</Page.Body>
			<Page.Footer />
		</Page>
	);
};

export default GameWhoWantScore;
