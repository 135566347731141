/* eslint-disable no-debugger */
import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoChevronForward } from 'react-icons/io5';

import { useAxios } from '../../hooks';
import {
	Button,
	Modal,
	Input,
	Select,
	Switch,
	Page,
	Table,
	Time,
} from '../../components';

const ContentCategories = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveContentCategory,
		setValue: setValueSaveContentCategory,
		watch: watchSaveContentCategory,
		handleSubmit: handleSubmitSaveContentCategory,
		reset: resetSaveContentCategory,
		formState: { errors: errorsSaveCategory },
	} = useForm();

	const api = {
		getContentCategoriesTV: useAxios(),
		getContentCategoriesMagazine: useAxios(),
		saveContentCategory: useAxios(),
		removeContentCategory: useAxios(),
	};

	const [contentCategoriesTV, setContentCategoriesTV] = React.useState([]);

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getContentCategoriesTV.response?.data) {
			setContentCategoriesTV(api.getContentCategoriesTV.response.data);
		}
	}, [api.getContentCategoriesTV.response?.data]);

	const [contentCategoriesMagazine, setContentCategoriesMagazine] =
		React.useState([]); // Listeyi state olarak tut

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getContentCategoriesMagazine.response?.data) {
			setContentCategoriesMagazine(
				api.getContentCategoriesMagazine.response.data
			);
		}
	}, [api.getContentCategoriesMagazine.response?.data]);

	/* getContentCategoriesTV */

	const getContentCategoriesTV = () => {
		api.getContentCategoriesTV.fetch({
			method: 'GET',
			url: '/magazine/admin/topicCategoryList/TV',
		});
	};

	React.useEffect(() => {
		getContentCategoriesTV();
	}, []);

	React.useEffect(() => {
		if (api.getContentCategoriesTV.error) {
			toast.error(api.getContentCategoriesTV.error.message);
		}
	}, [api.getContentCategoriesTV.error]);

	/* getContentCategoriesMagazine */

	const getContentCategoriesMagazine = () => {
		api.getContentCategoriesMagazine.fetch({
			method: 'GET',
			url: '/magazine/admin/topicCategoryList/MAGAZINE',
		});
	};

	React.useEffect(() => {
		getContentCategoriesMagazine();
	}, []);

	React.useEffect(() => {
		if (api.getContentCategoriesMagazine.error) {
			toast.error(api.getContentCategoriesMagazine.error.message);
		}
	}, [api.getContentCategoriesMagazine.error]);

	/* saveContentCategory */

	const saveContentCategory = (data) => {
		console.log(data);
		const body = {
			...data,
			description: '', // TODO: get ile gelmiyor
			active: !!(data.active === 'true' || data.active === true),
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
			...(data.id ? { id: parseInt(data.id, 10) } : null),
			...(data.topicCategoryId
				? { topicCategoryId: parseInt(data.topicCategoryId, 10) }
				: null),
		};

		if (data.topicCategoryId === '0') {
			delete body.topicCategoryId;
		}

		api.saveContentCategory.fetch({
			method: 'POST',
			url: `${
				data.subId
					? '/magazine/admin/saveTopicSubCategory'
					: '/magazine/admin/saveTopicCategory'
			}`,
			data: body,
		});
	};

	/* saveContentSubCategory */

	const saveContentSubCategory = (data) => {
		console.log(data);
		const body = {
			...data,
			description: '', // TODO: get ile gelmiyor
			active: !!(data.active === 'true' || data.active === true),
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
			...(data.id ? { id: parseInt(data.id, 10) } : null),
			...(data.topicCategoryId
				? { topicCategoryId: parseInt(data.topicCategoryId, 10) }
				: null),
		};
		console.log(body);
		api.saveContentCategory.fetch({
			method: 'POST',
			url: `${'/magazine/admin/saveTopicSubCategory'}`,
			data: body,
		});
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit === true) {
			setValueSaveContentCategory('topicPageType', 'TV', {
				shouldValidate: true,
			});
		}

		if (
			showEdit.id &&
			api.getContentCategoriesTV.response &&
			api.getContentCategoriesMagazine.response
		) {
			const { id, subId, type } = showEdit;
			const data =
				type === 'TV'
					? api.getContentCategoriesTV.response.data.content
					: api.getContentCategoriesMagazine.response.data.content;

			const item = subId
				? data[data.findIndex((object) => object.id === id)]
						.topicSubCategoryList[
						data[
							data.findIndex((object) => object.id === id)
						].topicSubCategoryList.findIndex(
							(object) => object.id === subId
						)
					]
				: data[data.findIndex((object) => object.id === id)];

			const values = [
				{ topicPageType: item.topicPageType },
				{ name: item.name },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
				...(subId
					? [
							{
								topicCategoryId: id,
							},
						]
					: []),
			];

			values.forEach((value) => {
				setValueSaveContentCategory(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [
		showEdit,
		api.getContentCategoriesTV.response,
		api.getContentCategoriesMagazine.response,
	]);

	React.useEffect(() => {
		if (api.saveContentCategory.response) {
			resetSaveContentCategory();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getContentCategoriesTV();
			getContentCategoriesMagazine();
		}
	}, [api.saveContentCategory.response]);

	React.useEffect(() => {
		if (api.saveContentCategory.error) {
			toast.error(api.saveContentCategory.error.message);
		}
	}, [api.saveContentCategory.error]);

	/* removeContentCategory */

	const removeCategory = (id) => {
		api.removeContentCategory.fetch({
			method: 'POST',
			url: `${
				showEdit.subId
					? '/magazine/admin/topicSubCategory/delete/?topicSubCategoryId='
					: '/magazine/admin/topicCategory/delete/?topicCategoryId='
			}${id}`,
		});
	};

	React.useEffect(() => {
		if (api.removeContentCategory.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getContentCategoriesTV();
			getContentCategoriesMagazine();
		}
	}, [api.removeContentCategory.response]);

	React.useEffect(() => {
		if (api.removeContentCategory.error) {
			toast.error(api.removeContentCategory.error.message);
		}
	}, [api.removeContentCategory.error]);

	/* return */

	return (
		<Page>
			<Page.Header header="İçerikler" title="İçerik Kategorileri">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={
					api.getContentCategoriesTV.loading ||
					api.getContentCategoriesMagazine.loading
				}
				show={
					!api.getContentCategoriesTV.loading &&
					api.getContentCategoriesTV.response &&
					!api.getContentCategoriesMagazine.loading &&
					api.getContentCategoriesMagazine.response
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Kategori',
								'Tür',
								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{contentCategoriesTV?.content?.length &&
								contentCategoriesTV?.content.map(
									(item, index) => (
										<>
											<Table.Row key={index}>
												<Table.Column>
													<span className="whitespace-nowrap text-xs font-normal text-slate-400">
														{item.id}
													</span>
												</Table.Column>
												<Table.Column>
													<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
														{item.name}
													</h6>
												</Table.Column>
												<Table.Column>
													{item.topicPageType ===
														'TV' && 'TV'}
													{item.topicPageType ===
														'MAGAZINE' && 'Magazin'}
												</Table.Column>
												<Table.Column>
													<Time
														dateBegin={moment(
															item.dateBegin
														).format('DD.MM.YYYY')}
														timeBegin={moment(
															item.dateBegin
														).format('HH:mm:ss')}
														dateEnd={moment(
															item.dateEnd
														).format('DD.MM.YYYY')}
														timeEnd={moment(
															item.dateEnd
														).format('HH:mm:ss')}
													/>
												</Table.Column>
												<Table.Column>
													<Switch
														id={item.id}
														active={item.active}
														list={
															contentCategoriesTV.content
														}
														onChange={
															setContentCategoriesTV
														}
														saveList={
															saveContentCategory
														}
														customPopUpText={false}
													/>
												</Table.Column>
												<Table.Column className="text-right [&_button]:w-20">
													<Button
														size="xs"
														variant="secondary-outline"
														className="inline-flex"
														onClick={() => {
															setShowEdit({
																id: item.id,
																type: item.topicPageType,
															});
														}}
													>
														Düzenle
													</Button>
												</Table.Column>
											</Table.Row>
											{item.topicSubCategoryList &&
												item.topicSubCategoryList.map(
													(
														subCategory,
														subCategoryIndex
													) => (
														<Table.Row
															key={`${index}.${subCategoryIndex}`}
															className={`${
																item
																	.topicSubCategoryList
																	.length ===
																subCategoryIndex +
																	1
																	? 'border-b-[16px]'
																	: ''
															}`}
														>
															<Table.Column>
																<span className="whitespace-nowrap text-xs font-normal text-slate-400">
																	{
																		subCategory.id
																	}
																</span>
															</Table.Column>
															<Table.Column>
																<h6 className="flex items-center gap-2 truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
																	<span className="opacity-50">
																		{
																			item.name
																		}
																	</span>
																	<IoChevronForward className="h-3 w-3 opacity-50" />
																	{
																		subCategory.name
																	}
																</h6>
															</Table.Column>
															<Table.Column>
																{item.topicPageType ===
																	'TV' &&
																	'TV'}
																{item.topicPageType ===
																	'MAGAZINE' &&
																	'Magazin'}
															</Table.Column>
															<Table.Column>
																<Time
																	dateBegin={moment(
																		subCategory.dateBegin
																	).format(
																		'DD.MM.YYYY'
																	)}
																	timeBegin={moment(
																		subCategory.dateBegin
																	).format(
																		'HH:mm:ss'
																	)}
																	dateEnd={moment(
																		subCategory.dateEnd
																	).format(
																		'DD.MM.YYYY'
																	)}
																	timeEnd={moment(
																		subCategory.dateEnd
																	).format(
																		'HH:mm:ss'
																	)}
																/>
															</Table.Column>
															<Table.Column>
																<Switch
																	id={
																		subCategory.id
																	}
																	active={
																		subCategory.active
																	}
																	list={
																		item.topicSubCategoryList
																	}
																	onChange={(
																		updatedSubCategories
																	) => {
																		// Alt kategori listesini güncelle
																		const updatedContent =
																			contentCategoriesTV.content.map(
																				(
																					category
																				) => {
																					if (
																						category.id ===
																						item.id
																					) {
																						return {
																							...category,
																							topicSubCategoryList:
																								updatedSubCategories,
																						};
																					}
																					return category;
																				}
																			);
																		setContentCategoriesTV(
																			{
																				...contentCategoriesTV,
																				content:
																					updatedContent,
																			}
																		);
																	}}
																	saveList={
																		saveContentSubCategory
																	}
																	customPopUpText={
																		false
																	}
																/>
															</Table.Column>
															<Table.Column className="text-right [&_button]:w-20">
																<Button
																	size="xs"
																	variant="secondary-outline"
																	className="inline-flex"
																	onClick={() => {
																		setShowEdit(
																			{
																				id: item.id,
																				subId: subCategory.id,
																				type: item.topicPageType,
																			}
																		);
																	}}
																>
																	Düzenle
																</Button>
															</Table.Column>
														</Table.Row>
													)
												)}
										</>
									)
								)}
							{contentCategoriesMagazine?.content?.length &&
								contentCategoriesMagazine?.content.map(
									(item, index) => (
										<>
											<Table.Row key={index}>
												<Table.Column>
													<span className="whitespace-nowrap text-xs font-normal text-slate-400">
														{item.id}
													</span>
												</Table.Column>
												<Table.Column>
													<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
														{item.name}
													</h6>
												</Table.Column>
												<Table.Column>
													{item.topicPageType ===
														'TV' && 'TV'}
													{item.topicPageType ===
														'MAGAZINE' && 'Magazin'}
												</Table.Column>
												<Table.Column>
													<Time
														dateBegin={moment(
															item.dateBegin
														).format('DD.MM.YYYY')}
														timeBegin={moment(
															item.dateBegin
														).format('HH:mm:ss')}
														dateEnd={moment(
															item.dateEnd
														).format('DD.MM.YYYY')}
														timeEnd={moment(
															item.dateEnd
														).format('HH:mm:ss')}
													/>
												</Table.Column>
												<Table.Column>
													<Switch
														id={item.id}
														active={item.active}
														list={
															contentCategoriesMagazine.content
														}
														onChange={
															setContentCategoriesMagazine
														}
														saveList={
															saveContentCategory
														}
														customPopUpText={false}
													/>
												</Table.Column>
												<Table.Column className="text-right [&_button]:w-20">
													<Button
														size="xs"
														variant="secondary-outline"
														className="inline-flex"
														onClick={() => {
															setShowEdit({
																id: item.id,
																type: item.topicPageType,
															});
														}}
													>
														Düzenle
													</Button>
												</Table.Column>
											</Table.Row>
											{item.topicSubCategoryList &&
												item.topicSubCategoryList.map(
													(
														subCategory,
														subCategoryIndex
													) => (
														<Table.Row
															key={`${index}.${subCategoryIndex}`}
															className={`${
																item
																	.topicSubCategoryList
																	.length ===
																subCategoryIndex +
																	1
																	? 'border-b-[16px]'
																	: ''
															}`}
														>
															<Table.Column>
																<span className="whitespace-nowrap text-xs font-normal text-slate-400">
																	{
																		subCategory.id
																	}
																</span>
															</Table.Column>
															<Table.Column>
																<h6 className="flex items-center gap-2 truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
																	<span className="opacity-50">
																		{
																			item.name
																		}
																	</span>
																	<IoChevronForward className="h-3 w-3 opacity-50" />
																	{
																		subCategory.name
																	}
																</h6>
															</Table.Column>
															<Table.Column>
																{item.topicPageType ===
																	'TV' &&
																	'TV'}
																{item.topicPageType ===
																	'MAGAZINE' &&
																	'Magazin'}
															</Table.Column>
															<Table.Column>
																<Time
																	dateBegin={moment(
																		subCategory.dateBegin
																	).format(
																		'DD.MM.YYYY'
																	)}
																	timeBegin={moment(
																		subCategory.dateBegin
																	).format(
																		'HH:mm:ss'
																	)}
																	dateEnd={moment(
																		subCategory.dateEnd
																	).format(
																		'DD.MM.YYYY'
																	)}
																	timeEnd={moment(
																		subCategory.dateEnd
																	).format(
																		'HH:mm:ss'
																	)}
																/>
															</Table.Column>
															<Table.Column>
																<Switch
																	id={
																		subCategory.id
																	}
																	active={
																		subCategory.active
																	}
																	list={
																		item.topicSubCategoryList
																	}
																	onChange={(
																		updatedSubCategories
																	) => {
																		// Alt kategori listesini güncelle
																		const updatedContent =
																			contentCategoriesMagazine.content.map(
																				(
																					category
																				) => {
																					if (
																						category.id ===
																						item.id
																					) {
																						return {
																							...category,
																							topicSubCategoryList:
																								updatedSubCategories,
																						};
																					}
																					return category;
																				}
																			);
																		setContentCategoriesMagazine(
																			{
																				...contentCategoriesMagazine,
																				content:
																					updatedContent,
																			}
																		);
																	}}
																	saveList={
																		saveContentSubCategory
																	}
																	customPopUpText={
																		false
																	}
																/>
															</Table.Column>
															<Table.Column className="text-right [&_button]:w-20">
																<Button
																	size="xs"
																	variant="secondary-outline"
																	className="inline-flex"
																	onClick={() => {
																		setShowEdit(
																			{
																				id: item.id,
																				subId: subCategory.id,
																				type: item.topicPageType,
																			}
																		);
																	}}
																>
																	Düzenle
																</Button>
															</Table.Column>
														</Table.Row>
													)
												)}
										</>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getContentCategoriesTV.loading ||
					api.getContentCategoriesMagazine.loading ||
					api.saveContentCategory.loading
					// api.removeContentCategory.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveContentCategory();
				}}
			>
				<form
					onSubmit={handleSubmitSaveContentCategory(
						saveContentCategory
					)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						{showEdit === true && (
							<Select
								name="topicPageType"
								label="Tür"
								grid
								options={[
									{ name: 'TV', value: 'TV' },
									{ name: 'Magazin', value: 'MAGAZINE' },
								]}
								defaultValue="TV"
								register={registerSaveContentCategory}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveCategory}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						)}
						{showEdit === true &&
							watchSaveContentCategory('topicPageType') ===
								'TV' && (
								<Select
									name="topicCategoryId"
									label="Üst Kategori"
									grid
									options={[
										...(!showEdit?.subId
											? [{ name: 'Yok', value: 0 }]
											: []),
										...(api.getContentCategoriesTV.response
											?.data?.content?.length
											? api.getContentCategoriesTV.response.data.content.map(
													(item) => ({
														name: item.name,
														value: item.id,
													})
												)
											: []),
									]}
									defaultValue={0}
									register={registerSaveContentCategory}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveCategory}
									size="lg"
									className={{ select: '!text-sm' }}
								/>
							)}
						{showEdit === true &&
							watchSaveContentCategory('topicPageType') ===
								'MAGAZINE' && (
								<Select
									name="topicCategoryId"
									label="Üst Kategori"
									grid
									options={[
										...(!showEdit?.subId
											? [{ name: 'Yok', value: 0 }]
											: []),
										...(api.getContentCategoriesMagazine
											.response?.data?.content?.length
											? api.getContentCategoriesMagazine.response.data.content.map(
													(item) => ({
														name: item.name,
														value: item.id,
													})
												)
											: []),
									]}
									defaultValue={0}
									register={registerSaveContentCategory}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveCategory}
									size="lg"
									className={{ select: '!text-sm' }}
								/>
							)}
						<Input
							type="text"
							name="name"
							label="İsim"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveContentCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now()).format(
								'YYYY-MM-DD HH:mm'
							)}
							register={registerSaveContentCategory}
							errors={errorsSaveCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now())
								.add(1, 'year')
								.format('YYYY-MM-DD HH:mm')}
							register={registerSaveContentCategory}
							errors={errorsSaveCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveContentCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveCategory}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					{(showEdit.id || showEdit.subId) && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveContentCategory}
							defaultValue={
								showEdit.subId ? showEdit.subId : showEdit.id
							}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{(showEdit.id || showEdit.subId) && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => {
									removeCategory(
										showEdit.subId || showEdit.id
									);
								}}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveCategory).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default ContentCategories;
