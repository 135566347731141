import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { IoChevronForward } from 'react-icons/io5';
import { BsFolder } from 'react-icons/bs';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useAxios } from '../../hooks';
import {
	Button,
	Modal,
	Input,
	Select,
	Switch,
	Page,
	Table,
	Time,
} from '../../components';

ChartJS.register(ArcElement);

const ProductCategories = () => {
	const [dashboardItems, setDashboardItems] = React.useState();
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveProductCategory,
		setValue: setValueSaveProductCategory,
		handleSubmit: handleSubmitSaveProductCategory,
		reset: resetSaveProductCategory,
		formState: { errors: errorsSaveCategory },
	} = useForm();

	const api = {
		getDashboard: useAxios(),
		getProductCategories: useAxios(),
		saveProductCategory: useAxios(),
		removeProductCategory: useAxios(),
	};

	const [productCategories, setProductCategories] = React.useState([]);

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getProductCategories.response?.data) {
			setProductCategories(api.getProductCategories.response.data);
		}
	}, [api.getProductCategories.response?.data]);

	const getDashboard = () => {
		api.getDashboard.fetch({
			method: 'GET',
			url: '/catalog/admin/dashboard/categories',
		});
	};

	const getProductCategories = () => {
		api.getProductCategories.fetch({
			method: 'GET',
			url: '/catalog/admin/categoryList',
		});
	};

	const saveProductCategory = (data) => {
		const body = {
			...data,
			active: !!(data.active === 'true' || data.active === true),
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
			...(data.id ? { id: parseInt(data.id, 10) } : null),
		};

		if (data.categoryId === '0') {
			delete body.categoryId;
		}

		api.saveProductCategory.fetch({
			method: 'POST',
			url: body.categoryId
				? '/catalog/admin/subCategory/save'
				: '/catalog/admin/category/save',
			data: body,
		});
	};

	const removeCategory = (id) => {
		api.removeProductCategory.fetch({
			method: 'POST',
			url: `${
				showEdit.subId
					? '/catalog/admin/subCategory/delete/'
					: '/catalog/admin/category/delete/'
			}${id}`,
		});
	};

	React.useEffect(() => {
		getDashboard();
		getProductCategories();
	}, []);

	React.useEffect(() => {
		if (api.getDashboard.response) {
			setDashboardItems([
				{
					id: 'toplam',
					name: 'Toplam',
					value: api.getDashboard.response.data.categorySize,
					icon: <BsFolder className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'aktif',
					name: 'Aktif',
					value: api.getDashboard.response.data.activeSize,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Aktif', 'Pasif'],
								datasets: [
									{
										data: [
											api.getDashboard.response.data
												.activeSize,
											api.getDashboard.response.data
												.passiveSize,
										],
										backgroundColor: ['#a7f3d0', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
				{
					id: 'pasif',
					name: 'Pasif',
					value: api.getDashboard.response.data.passiveSize,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Pasif', 'Aktif'],
								datasets: [
									{
										data: [
											api.getDashboard.response.data
												.passiveSize,
											api.getDashboard.response.data
												.activeSize,
										],
										backgroundColor: ['#fecdd3', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
			]);
		}
	}, [api.getDashboard.response]);

	React.useEffect(() => {
		if (api.getProductCategories.error) {
			toast.error(api.getProductCategories.error.message);
		}
	}, [api.getProductCategories.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getProductCategories.response) {
			const { id, subId } = showEdit;
			const item = subId
				? api.getProductCategories.response.data[
						api.getProductCategories.response.data.findIndex(
							(object) => object.id === id
						)
					].subCategoryList[
						api.getProductCategories.response.data[
							api.getProductCategories.response.data.findIndex(
								(object) => object.id === id
							)
						].subCategoryList.findIndex(
							(object) => object.id === subId
						)
					]
				: api.getProductCategories.response.data[
						api.getProductCategories.response.data.findIndex(
							(object) => object.id === id
						)
					];
			const values = [
				{ name: item.name },
				{ code: item.code },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
				...(subId
					? [
							{
								categoryId: id,
							},
						]
					: []),
			];

			values.forEach((value) => {
				setValueSaveProductCategory(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEdit, api.getProductCategories.response]);

	React.useEffect(() => {
		if (api.saveProductCategory.response) {
			resetSaveProductCategory();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getProductCategories();
		}
	}, [api.saveProductCategory.response]);

	React.useEffect(() => {
		if (api.saveProductCategory.error) {
			toast.error(api.saveProductCategory.error.message);
		}
	}, [api.saveProductCategory.error]);

	React.useEffect(() => {
		if (api.removeProductCategory.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getProductCategories();
		}
	}, [api.removeProductCategory.response]);

	React.useEffect(() => {
		if (api.removeProductCategory.error) {
			toast.error(api.removeProductCategory.error.message);
		}
	}, [api.removeProductCategory.error]);

	return (
		<Page>
			<Page.Header header="Puanlı Ürünler" title="Ürün Kategorileri">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getProductCategories.loading}
				show={
					!api.getProductCategories.loading &&
					api.getProductCategories.response
				}
			>
				<Page.Dashboard items={dashboardItems} />
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Kategori',
								'Kod',
								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{productCategories?.length &&
								productCategories?.map((item, index) => (
									<>
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.name}
												</h6>
											</Table.Column>
											<Table.Column>
												{item.code}
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											,
											<Table.Column>
												<Switch
													id={item.id}
													active={item.active}
													list={productCategories}
													onChange={
														setProductCategories
													}
													saveList={
														saveProductCategory
													}
													customPopUpText={false}
												/>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
										{item.subCategoryList &&
											item.subCategoryList.map(
												(
													subCategory,
													subCategoryIndex
												) => (
													<Table.Row
														key={`${index}.${subCategoryIndex}`}
														className={`${
															item.subCategoryList
																.length ===
															subCategoryIndex + 1
																? 'border-b-[16px]'
																: ''
														}`}
													>
														<Table.Column>
															<span className="whitespace-nowrap text-xs font-normal text-slate-400">
																{subCategory.id}
															</span>
														</Table.Column>
														<Table.Column>
															<h6 className="flex items-center gap-2 truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
																<span className="opacity-50">
																	{item.name}
																</span>
																<IoChevronForward className="h-3 w-3 opacity-50" />
																{
																	subCategory.name
																}
															</h6>
														</Table.Column>
														<Table.Column>
															{subCategory.code}
														</Table.Column>
														<Table.Column>
															<Time
																dateBegin={moment(
																	subCategory.dateBegin
																).format(
																	'DD.MM.YYYY'
																)}
																timeBegin={moment(
																	subCategory.dateBegin
																).format(
																	'HH:mm:ss'
																)}
																dateEnd={moment(
																	subCategory.dateEnd
																).format(
																	'DD.MM.YYYY'
																)}
																timeEnd={moment(
																	subCategory.dateEnd
																).format(
																	'HH:mm:ss'
																)}
															/>
														</Table.Column>
														<Table.Column>
															<Switch
																id={
																	subCategory.id
																}
																active={
																	subCategory.active
																}
																list={
																	item.subCategoryList
																}
																onChange={(
																	updatedSubCategories
																) => {
																	// Alt kategori listesini güncelle
																	const updatedContent =
																		productCategories.map(
																			(
																				category
																			) => {
																				if (
																					category.id ===
																					item.id
																				) {
																					return {
																						...category,
																						subCategoryList:
																							updatedSubCategories,
																					};
																				}
																				return category;
																			}
																		);
																	setProductCategories(
																		{
																			...productCategories,
																		}
																	);
																}}
																saveList={
																	saveProductCategory
																}
																customPopUpText={
																	false
																}
															/>
														</Table.Column>
														<Table.Column className="text-right [&_button]:w-20">
															<Button
																size="xs"
																variant="secondary-outline"
																className="inline-flex"
																onClick={() => {
																	setShowEdit(
																		{
																			id: item.id,
																			subId: subCategory.id,
																		}
																	);
																}}
															>
																Düzenle
															</Button>
														</Table.Column>
													</Table.Row>
												)
											)}
									</>
								))}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getProductCategories.loading ||
					api.saveProductCategory.loading ||
					api.removeProductCategory.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveProductCategory();
				}}
			>
				<form
					onSubmit={handleSubmitSaveProductCategory(
						saveProductCategory
					)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						<Select
							name="categoryId"
							label="Üst Kategori"
							grid
							options={[
								...(!showEdit?.subId
									? [{ name: 'Yok', value: 0 }]
									: []),
								...(api.getProductCategories.response?.data
									?.length
									? api.getProductCategories.response.data.map(
											(item) => ({
												name: item.name,
												value: item.id,
											})
										)
									: []),
							]}
							defaultValue={0}
							register={registerSaveProductCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveCategory}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<Input
							type="text"
							name="name"
							label="İsim"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProductCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="text"
							name="code"
							label="Kod"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProductCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now()).format(
								'YYYY-MM-DD HH:mm'
							)}
							register={registerSaveProductCategory}
							errors={errorsSaveCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProductCategory}
							errors={errorsSaveCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveProductCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveCategory}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					{(showEdit.id || showEdit.subId) && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveProductCategory}
							defaultValue={
								showEdit.subId ? showEdit.subId : showEdit.id
							}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{(showEdit.id || showEdit.subId) && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => {
									removeCategory(
										showEdit.subId || showEdit.id
									);
								}}
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveCategory).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default ProductCategories;
