import React from 'react';
import { IoChevronDown, IoAlertCircle } from 'react-icons/io5';

const Select = ({
	id,
	name,
	label,
	grid,
	register,
	validation,
	errors,
	options,
	defaultValue,
	value,
	className,
	size = 'md',
	disabled,
	onChange,
}) => {
	const sizes = {
		xs: 'h-7 pl-2 text-xs',
		sm: 'h-9 pl-2.5 text-sm',
		md: 'h-10 pl-3 text-sm',
		lg: 'h-12 pl-3.5 text-base',
		xl: 'h-14 pl-4 text-lg',
	};

	return (
		<div
			className={`group 
	 		${errors && errors[name] ? 'invalid' : ''} 
			${(className && className.group) || ''}
		`}
		>
			<div className={grid ? 'grid grid-cols-3 items-center' : ''}>
				{label && (
					<label
						className={`flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500 
						${grid ? 'col-span-1' : 'mb-2'}
						${(className && className.label) || ''}
					`}
					>
						{label}
					</label>
				)}
				<div className={`relative ${grid ? 'col-span-2' : 'w-full'}`}>
					<select
						name={name}
						id={id || name}
						defaultValue={defaultValue}
						value={value}
						className={`relative z-10 w-full cursor-pointer appearance-none rounded-lg border-2 pr-[calc(theme(spacing.4)+theme(spacing.3))] placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500 
							${sizes[size]} 
							${(className && className.select) || ''}
			    		`}
						disabled={disabled}
						onChange={onChange}
						{...(register &&
							register(name, validation && validation))}
					>
						{options &&
							options.map((option, index) => (
								<option key={index} value={option.value}>
									{option.name}
								</option>
							))}
					</select>
					<IoChevronDown className="pointer-events-none absolute right-2 top-1/2 z-10 h-4 w-4 -translate-y-1/2 transform" />
				</div>
			</div>

			{errors && errors[name] && (
				<div
					className={`
						relative -top-2 inline-flex gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500
						${grid ? 'left-1/3 w-2/3' : 'w-full'}
					`}
				>
					<IoAlertCircle className="h-5 w-5 shrink-0" />
					{errors[name].message}
				</div>
			)}
		</div>
	);
};

export default Select;
