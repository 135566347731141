import React from 'react';

const Widget = ({ children, title, value, badge, icon }) => (
	<div className="flex h-[360px] flex-col gap-6 rounded-lg p-6 shadow bg-white">
		<div className="flex shrink-0 items-center gap-4">
			<div className="overflow-hidden whitespace-nowrap">
				{title && (
					<h6 className="mb-1 truncate text-sm text-slate-400">
						{title}
					</h6>
				)}
				{(value || badge) && (
					<div className="flex items-center gap-3 truncate text-3xl font-medium">
						{value}
						{badge && badge}
					</div>
				)}
			</div>
			{icon && <div className="ml-auto shrink-0">{icon}</div>}
		</div>
		{children && children}
	</div>
);

export default Widget;
