import React from 'react';

const Time = ({ dateBegin, timeBegin, dateEnd, timeEnd }) => (
	<div className="grid gap-1">
		{(dateBegin || timeBegin) && (
			<div className="flex items-center gap-2">
				<div
					className={`flex ${
						dateEnd || timeEnd
							? 'items-center gap-2'
							: 'flex-col gap-1'
					}`}
				>
					{(dateEnd || timeEnd) && (
						<div className="aspect-square w-1.5 rounded-full bg-slate-400" />
					)}
					{dateBegin && <span>{dateBegin}</span>}
					{timeBegin && (
						<span className="text-xs text-slate-400">
							{timeBegin}
						</span>
					)}
				</div>
			</div>
		)}

		{(dateEnd || timeEnd) && (
			<div className="flex items-center gap-2">
				<div className="relative aspect-square w-1.5 rounded-full bg-slate-400 before:absolute before:bottom-full before:left-1/2 before:block before:h-[18px] before:w-0.5 before:-translate-x-1/2 before:transform before:content-[''] before:bg-slate-100" />
				{dateEnd && <span>{dateEnd}</span>}
				{timeEnd && (
					<span className="text-xs text-slate-400">{timeEnd}</span>
				)}
			</div>
		)}
	</div>
);

export default Time;
