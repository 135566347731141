import React, { useState } from 'react';
import { BsFillXCircleFill } from 'react-icons/bs';
import { FormInput, Modal } from '../../components';

const GameLuckyWheelQuestions = ({
	WheelPartList,
	errorsSaveGame,
	handleRemoveCategory,
	handleAddCategory,
	handleAddQuestions,
	handleAddChoice,
	handleRemoveChoice,
	setTrueChoice,
	handleChange,
	partTitle,
}) => {
	return (
		<Modal.Section title={partTitle}>
			{WheelPartList?.map((question, questionIndex) => (
				<div
					className="relative pr-8 rounded-lg border-2 p-4"
					key={question.id}
				>
					<FormInput
						id={`text-${question.id}`}
						name="text"
						grid
						type="text"
						label="Kategori Adı"
						value={question.text || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`color-${question.id}`}
						name="color"
						grid
						type="text"
						label="Renk Kodu"
						value={question.color || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`percentage-${question.id}`}
						name="percentage"
						grid
						type="text"
						label="Soru Gelme Yüzdesi"
						value={question.percentage || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`score-${question.id}`}
						name="score"
						grid
						type="number"
						label="Puan"
						value={question.score || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm' }}
					/>

					<FormInput
						id={`selectionLimit-${question.id}`}
						name="selectionLimit"
						grid
						type="text"
						label="Seçim Limiti"
						value={question.selectionLimit || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`textColor-${question.id}`}
						name="textColor"
						grid
						type="text"
						label="Yazı Rengi"
						value={question.textColor || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					{question?.luckyWheelQuestionDTOList && (
						<button
							className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600"
							type="button"
							onClick={() => {
								handleAddQuestions(question.id);
							}}
						>
							Yeni Soru Ekle
						</button>
					)}

					{question?.luckyWheelQuestionDTOList?.map(
						(item, itemIndex) => (
							<div
								key={item.id}
								className="mt-4 p-4 bg-slate-50 rounded-lg"
							>
								<FormInput
									id={`description-${item.id}`}
									name="description"
									grid
									type="text"
									label="Soru Açıklaması"
									value={item.description}
									onChange={(e) =>
										handleChange(e, question.id, item.id)
									}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveGame}
									size="lg"
									className={{ input: '!text-sm m-4' }}
								/>

								{item.luckyWheelChoiceDTOList?.length ? (
									item.luckyWheelChoiceDTOList.map(
										(choice, choiceIndex) => (
											<div
												className="relative pr-8"
												key={choice.id}
											>
												<FormInput
													id={`choice-${choice.id}`}
													name="description"
													grid
													type="text"
													label={`Seçenek ${choiceIndex + 1}`}
													value={choice.description}
													onChange={(e) =>
														handleChange(
															e,
															question.id,
															item.id,
															choice.id
														)
													}
													validation={{
														required:
															'Bu alan zorunludur.',
													}}
													errors={errorsSaveGame}
													size="lg"
													className={{
														input: '!text-sm',
													}}
												/>
												<div className="mb-4 mt-2 grid grid-cols-3">
													<div className="col-span-2 col-start-2 flex items-center gap-2 text-sm">
														<input
															id={`isTrue-${choice.id}`}
															name="isTrue"
															className="cursor-pointer"
															type="checkbox"
															checked={
																choice.isTrue
															}
															onChange={(e) =>
																handleChange(
																	e,
																	question.id,
																	item.id,
																	choice.id
																)
															}
														/>
														<label
															className="cursor-pointer"
															htmlFor={`isTrue-${choice.id}`}
														>
															Doğru yanıt
														</label>
													</div>
												</div>
												<button
													className="absolute right-0 top-3.5"
													type="button"
													onClick={() =>
														handleRemoveChoice(
															item.id,
															choice.id
														)
													}
												>
													<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
												</button>
											</div>
										)
									)
								) : (
									<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
										Seçenek bulunamadı
									</div>
								)}

								<button
									className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600"
									type="button"
									onClick={() =>
										handleAddChoice(question.id, item.id)
									}
								>
									Yeni Şık Ekle
								</button>
							</div>
						)
					)}

					<button
						className="absolute right-0 top-3.5"
						type="button"
						onClick={() => handleRemoveCategory(question.id)}
					>
						<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
					</button>
				</div>
			))}

			<button
				className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600"
				type="button"
				onClick={handleAddCategory}
			>
				Yeni Kategori Ekle
			</button>
		</Modal.Section>
	);
};

export default GameLuckyWheelQuestions;
