import React from 'react';
import { Transition } from '@headlessui/react';
import { CgClose, CgSpinner } from 'react-icons/cg';

import Section from './Section';

const Modal = ({ children, show, loading, size = 'md', title, onClose }) => {
	const sizes = {
		sm: '20rem',
		md: '28rem',
		lg: '36rem',
		xl: '48rem',
		xxl: '72rem',
	};

	return (
		<Transition show={show} className="relative z-50 inline">
			<Transition.Child
				enter="ease-out duration-300"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="ease-in duration-200"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className="fixed inset-0 bg-slate-900/75 backdrop-blur-sm"
			/>

			<Transition.Child
				enter="ease-out duration-300"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="ease-in duration-200"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
				className="fixed inset-0 overflow-y-auto"
			>
				<div className="flex min-h-full items-center justify-center p-4">
					<div
						className="relative max-w-full overflow-hidden rounded-lg shadow-md bg-white"
						style={{ width: sizes[size] }}
					>
						{(title || onClose) && (
							<div
								className={`flex items-center px-4 py-3 ${
									title && 'border-b-2 border-slate-100'
								}`}
							>
								{title && (
									<h4 className="text-lg font-medium text-slate-700">
										{title}
									</h4>
								)}
								{onClose && (
									<button
										type="button"
										className="ml-auto text-slate-500 hover:text-slate-600"
										onClick={onClose}
									>
										<CgClose className="h-6 w-6" />
									</button>
								)}
							</div>
						)}
						{children}
						{loading && (
							<div className="absolute inset-0 z-50 flex items-center justify-center bg-white/75 text-2xl backdrop-blur-sm">
								<CgSpinner className="animate-spin" />
							</div>
						)}
					</div>
				</div>
			</Transition.Child>
		</Transition>
	);
};

Modal.Section = Section;

export default Modal;
