/*

+ dailyLoginSize
+ weeklyLoginSizes
+ weeklyPercentageChange

+ monthlyLoginSize
+ monthlyPercentageChange
+ sixMonthlyLoginSizes

+ yearlyLoginSize
+ yearlyPercentageChange
+ threeYearlyLoginSizes

+ employeeDashboardDTO
+ employeeImageDashboardStatsDTO

orderDashboardDTO
+ productDashboardDTO
+ scoreDashboardDTO

*/

import React from 'react';
import moment from 'moment';
import {
	IoGiftOutline,
	IoCartOutline,
	IoPersonCircleOutline,
	IoTrophyOutline,
	IoMegaphoneOutline,
} from 'react-icons/io5';
import { AiOutlineLineChart } from 'react-icons/ai';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	PointElement,
	LineElement,
	Filler,
} from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { useAxios } from '../../hooks';
import {
	Badge,
	Page,
	Widget,
	Item,
	Thumbnails,
	Time,
	// Button,
} from '../../components';

ChartJS.register(
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	PointElement,
	LineElement,
	Filler
);

const Dashboard = () => {
	const [dashboardItems, setDashboardItems] = React.useState();

	const api = {
		getDashboard: useAxios(),
	};

	const getDashboard = () => {
		api.getDashboard.fetch({
			method: 'GET',
			url: '/statistics/admin/dashboard',
		});
	};

	React.useEffect(() => {
		getDashboard();
	}, []);

	React.useEffect(() => {
		if (api.getDashboard.response) {
			setDashboardItems([
				{
					id: 'gunluk-giris',
					name: 'Günlük Giriş',
					content: (
						<Widget
							title="Günlük Giriş"
							value={
								api.getDashboard.response.data
									?.dailyLoginSize || 0
							}
							badge={
								<Badge
									variant={
										api.getDashboard.response.data
											?.weeklyPercentageChange &&
										api.getDashboard.response.data
											.weeklyPercentageChange >= 0
											? 'success'
											: 'danger'
									}
								>
									%{' '}
									{api.getDashboard.response.data
										?.weeklyPercentageChange
										? Math.round(
											api.getDashboard.response.data
												.weeklyPercentageChange
										)
										: 0}
								</Badge>
							}
							icon={
								<AiOutlineLineChart className="h-8 w-8 opacity-25" />
							}
						>
							<div className="grow">
								<Line
									options={{
										maintainAspectRatio: false,
										plugins: {
											legend: {
												display: false,
											},
										},
										scales: {
											y: {
												display: false,
												beginAtZero: true,
											},
											x: {
												display: true,
												grid: {
													color: 'transparent',
												},
												ticks: {
													font: {
														family: '"Lexend Deca", sans-serif',
														weight: '300',
														size: 12,
													},
													color: '#94A3B8',
												},
											},
										},
									}}
									data={{
										labels: [
											...(api.getDashboard.response.data
												?.weeklyLoginSizes &&
											Array.isArray(
												api.getDashboard.response.data
													.weeklyLoginSizes
											)
												? api.getDashboard.response.data.weeklyLoginSizes.map(
													(item) => item.date
												)
												: []),
										],
										datasets: [
											{
												label: 'Giriş',
												data: [
													...(api.getDashboard
														.response.data
														?.weeklyLoginSizes &&
													Array.isArray(
														api.getDashboard
															.response.data
															.weeklyLoginSizes
													)
														? api.getDashboard.response.data.weeklyLoginSizes.map(
															(item) =>
																item.count
														)
														: []),
												],
												borderRadius: Number.MAX_VALUE,
												barThickness: 12,

												fill: true,
												borderColor: '#bfdbfe',
												backgroundColor: '#f0f9ff',

												pointBackgroundColor: '#bfdbfe',
												pointBorderWidth: 0,
												pointRadius: 4,
												tension: 0.2,
											},
										],
									}}
								/>
							</div>
						</Widget>
					),
				},
				{
					id: 'aylik-giris',
					name: 'Aylık Giriş',
					content: (
						<Widget
							title="Aylık Giriş"
							value={
								api.getDashboard.response.data
									?.monthlyLoginSize || 0
							}
							badge={
								<Badge
									variant={
										api.getDashboard.response.data
											?.monthlyPercentageChange &&
										api.getDashboard.response.data
											.monthlyPercentageChange >= 0
											? 'success'
											: 'danger'
									}
								>
									%{' '}
									{api.getDashboard.response.data
										?.monthlyPercentageChange
										? Math.round(
											api.getDashboard.response.data
												.monthlyPercentageChange
										)
										: 0}
								</Badge>
							}
							icon={
								<AiOutlineLineChart className="h-8 w-8 opacity-25" />
							}
						>
							<div className="grow">
								<Bar
									options={{
										maintainAspectRatio: false,
										plugins: {
											legend: {
												display: false,
											},
										},
										scales: {
											y: {
												display: false,
											},
											x: {
												display: true,
												grid: {
													color: 'transparent',
												},
												ticks: {
													font: {
														family: '"Lexend Deca", sans-serif',
														weight: '300',
														size: 12,
													},
													color: '#94A3B8',
												},
											},
										},
									}}
									data={{
										labels: [
											...(api.getDashboard.response.data
												?.sixMonthlyLoginSizes &&
											Array.isArray(
												api.getDashboard.response.data
													.sixMonthlyLoginSizes
											)
												? api.getDashboard.response.data.sixMonthlyLoginSizes.map(
													(item) => item.date
												)
												: []),
										],
										datasets: [
											{
												label: 'Giriş',
												data: [
													...(api.getDashboard
														.response.data
														?.sixMonthlyLoginSizes &&
													Array.isArray(
														api.getDashboard
															.response.data
															.sixMonthlyLoginSizes
													)
														? api.getDashboard.response.data.sixMonthlyLoginSizes.map(
															(item) =>
																item.count
														)
														: []),
												],
												backgroundColor: '#bfdbfe',
												borderRadius: Number.MAX_VALUE,
												barThickness: 12,
											},
										],
									}}
								/>
							</div>
						</Widget>
					),
				},
				{
					id: '1.3',
					name: 'Yıllık Giriş',
					content: (
						<Widget
							title="Yıllık Giriş"
							value={
								api.getDashboard.response.data
									?.yearlyLoginSize || 0
							}
							badge={
								<Badge
									variant={
										api.getDashboard.response.data
											?.yearlyPercentageChange &&
										api.getDashboard.response.data
											.yearlyPercentageChange >= 0
											? 'success'
											: 'danger'
									}
								>
									%{' '}
									{api.getDashboard.response.data
										?.yearlyPercentageChange
										? Math.round(
											api.getDashboard.response.data
												.yearlyPercentageChange
										)
										: 0}
								</Badge>
							}
							icon={
								<AiOutlineLineChart className="h-8 w-8 opacity-25" />
							}
						>
							<div className="grow">
								<Bar
									options={{
										maintainAspectRatio: false,
										plugins: {
											legend: {
												display: false,
											},
										},
										scales: {
											y: {
												display: false,
											},
											x: {
												display: true,
												grid: {
													color: 'transparent',
												},
												ticks: {
													font: {
														family: '"Lexend Deca", sans-serif',
														weight: '300',
														size: 12,
													},
													color: '#94A3B8',
												},
											},
										},
									}}
									data={{
										labels: [
											...(api.getDashboard.response.data
												?.threeYearlyLoginSizes &&
											Array.isArray(
												api.getDashboard.response.data
													.threeYearlyLoginSizes
											)
												? api.getDashboard.response.data.threeYearlyLoginSizes.map(
													(item) => item.date
												)
												: []),
										],
										datasets: [
											{
												label: 'Giriş',
												data: [
													...(api.getDashboard
														.response.data
														?.threeYearlyLoginSizes &&
													Array.isArray(
														api.getDashboard
															.response.data
															.threeYearlyLoginSizes
													)
														? api.getDashboard.response.data.threeYearlyLoginSizes.map(
															(item) =>
																item.count
														)
														: []),
												],
												backgroundColor: '#bfdbfe',
												borderRadius: Number.MAX_VALUE,
												barThickness: 12,
											},
										],
									}}
								/>
							</div>
						</Widget>
					),
				},
				{
					id: 'toplam-uye',
					name: 'Toplam Üye',
					content: (
						<Widget
							title="Toplam Üye"
							value={
								(api.getDashboard.response.data
									?.employeeDashboardDTO?.activeSize || 0) +
								(api.getDashboard.response.data
									?.employeeDashboardDTO?.passiveSize || 0)
							}
							icon={
								<IoPersonCircleOutline className="h-8 w-8 opacity-25" />
							}
						>
							<div className="grow">
								<Doughnut
									options={{
										maintainAspectRatio: false,
										plugins: {
											legend: {
												position: 'right',
												align: 'left',
												labels: {
													font: {
														family: '"Lexend Deca", sans-serif',
														weight: '300',
														size: 12,
													},
													color: '#94A3B8',
												},
											},
										},
									}}
									data={{
										labels: ['Aktif', 'Pasif'],
										datasets: [
											{
												data: [
													api.getDashboard.response
														.data
														?.employeeDashboardDTO
														?.activeSize || 0,
													api.getDashboard.response
														.data
														?.employeeDashboardDTO
														?.passiveSize || 0,
												],
												backgroundColor: [
													'#dcfce7',
													'#fee2e2',
												],
												borderWidth: 0,
											},
										],
									}}
								/>
							</div>
						</Widget>
					),
				},
				/*
				{
					id: 'sozlesmeli-uye',
					name: 'Sözleşmeli Üye',
					content: (
						<Widget
							title="Sözleşmeli Üye"
							value="20,135"
							icon={
								<IoPersonCircleOutline className="h-8 w-8 opacity-25" />
							}
						>
							<div className="grow">
								<Doughnut
									options={{
										maintainAspectRatio: false,
										plugins: {
											legend: {
												position: 'right',
												align: 'left',
												labels: {
													font: {
														family: '"Lexend Deca", sans-serif',
														weight: '300',
														size: 12,
													},
													color: '#94A3B8',
												},
											},
										},
									}}
									data={{
										labels: ['Sözleşmeli', 'Sözleşmesiz'],
										datasets: [
											{
												data: [20135, 14720],
												backgroundColor: [
													'#dcfce7',
													'#fee2e2',
												],
												borderWidth: 0,
											},
										],
									}}
								/>
							</div>
						</Widget>
					),
				},
				*/
				{
					id: 'uyelik-onaylari',
					name: 'Üyelik Onayları',
					content: (
						<div className="grid h-[360px] grid-cols-1 gap-4">
							<div className="flex items-center gap-4 rounded-lg p-6 shadow bg-white">
								<div className="overflow-hidden whitespace-nowrap">
									<h6 className="mb-1 truncate text-sm text-slate-400">
										Onay Bekleyen Üyeler
									</h6>
									<div className="truncate text-3xl font-medium">
										{api.getDashboard.response.data
											?.employeeDashboardDTO
											?.waitingSize || 0}
									</div>
								</div>
								<div className="ml-auto shrink-0">
									<Thumbnails
										max={5}
										images={[
											/* eslint-disable */
											...(api.getDashboard.response.data
												?.employeeDashboardDTO
												?.employeeWaitingImageDTOList &&
											Array.isArray(
												api.getDashboard.response.data
													.employeeDashboardDTO
													.employeeWaitingImageDTOList
											)
												? api.getDashboard.response.data.employeeDashboardDTO.employeeWaitingImageDTOList.map(
														(item) => ({
															url: item.imageUrl,
															name:
																item.name +
																' ' +
																item.surname,
														})
													)
												: []),
											/* eslint-enable */
										]}
										className="[&_img]:h-12 [&_img]:w-12 [&_img]:p-0 [&>div:not(:first-child)]:-ml-4"
									/>
								</div>
							</div>
							<div className="flex items-center gap-4 rounded-lg p-6 shadow bg-white">
								<div className="overflow-hidden whitespace-nowrap">
									<h6 className="mb-1 truncate text-sm text-slate-400">
										Onay Bekleyen Fotoğraflar
									</h6>
									<div className="truncate text-3xl font-medium">
										{api.getDashboard.response.data
											?.employeeImageDashboardStatsDTO
											?.waiting || 0}
									</div>
								</div>
								<div className="ml-auto shrink-0">
									<Thumbnails
										max={5}
										images={[
											/* eslint-disable */
											...(api.getDashboard.response.data
												?.employeeImageDashboardStatsDTO
												?.employeeImageWaitingDTOList &&
											Array.isArray(
												api.getDashboard.response.data
													.employeeImageDashboardStatsDTO
													.employeeImageWaitingDTOList
											)
												? api.getDashboard.response.data.employeeImageDashboardStatsDTO.employeeImageWaitingDTOList.map(
														(item) => ({
															url: item.imageUrl,
														})
													)
												: []),
											/* eslint-enable */
										]}
										className="[&_img]:h-12 [&_img]:w-12 [&_img]:p-0 [&>div:not(:first-child)]:-ml-4"
									/>
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'hatirlatmalar',
					name: 'Hatırlatmalar',
					content: (
						<Widget title="Hatırlatmalar" value="0">
							<div className="-mt-6 flex grow flex-col gap-3 overflow-y-auto py-6 [mask-image:linear-gradient(to_bottom,transparent_0%,black_theme(spacing.6),black_calc(100%-theme(spacing.6)),transparent_100%)]">
								<div className="flex grow flex-col items-center justify-center gap-4">
									<IoMegaphoneOutline className="h-12 w-12 opacity-25" />
									<h3 className="text-sm font-normal text-slate-400">
										Hiç hatırlatmanız yok.
									</h3>
								</div>
							</div>
						</Widget>
					),
				},
				{
					id: 'stoktaki-urunler',
					name: 'Stoktaki Ürünler',
					content: (
						<div className="grid h-[360px] grid-cols-1 gap-4">
							<div className="flex items-center gap-4 rounded-lg p-6 shadow bg-white">
								<div className="overflow-hidden whitespace-nowrap">
									<h6 className="mb-1 truncate text-sm text-slate-400">
										Stoktaki Ürünler
									</h6>
									<div className="truncate text-3xl font-medium">
										{api.getDashboard.response.data
											?.productDashboardDTO
											?.hasStockSize || 0}
									</div>
								</div>
								<div className="ml-auto shrink-0">
									<IoGiftOutline className="h-8 w-8 opacity-25" />
								</div>
							</div>
							<div className="flex items-center gap-4 rounded-lg p-6 shadow bg-white">
								<div className="overflow-hidden whitespace-nowrap">
									<h6 className="mb-1 truncate text-sm text-slate-400">
										Ürün Çeşidi
									</h6>
									<div className="truncate text-3xl font-medium">
										{api.getDashboard.response.data
											?.productDashboardDTO
											?.productSize || 0}
									</div>
								</div>
								<div className="ml-auto shrink-0">
									<IoGiftOutline className="h-8 w-8 opacity-25" />
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'dusuk-stoklu-urunler',
					name: 'Düşük Stoklu Ürünler',
					content: (
						<Widget
							title="Düşük Stoklu Ürünler"
							value={
								api.getDashboard.response.data
									?.productDashboardDTO?.lowStockSize || 0
							}
							icon={
								<IoGiftOutline className="h-8 w-8 opacity-25" />
							}
						>
							<div className="-mt-6 flex grow flex-col gap-3 overflow-y-auto py-6 [mask-image:linear-gradient(to_bottom,transparent_0%,black_theme(spacing.6),black_calc(100%-theme(spacing.6)),transparent_100%)]">
								{api.getDashboard.response.data
									?.productDashboardDTO
									?.productImageDTOList &&
									Array.isArray(
										api.getDashboard.response.data
											.productDashboardDTO
											.productImageDTOList
									) &&
									api.getDashboard.response.data.productDashboardDTO.productImageDTOList.map(
										(item, index) => (
											<div className="flex items-center justify-between gap-6 border-b-2 pb-3 border-slate-100" key={item.title + index}>
												<Item
													image={item.imageUrl}
													title={item.title}
													description={
														item.description
													}
													className="gap-3"
													size="sm"
												/>
												<div className="flex items-center gap-2 text-right text-base font-medium">
													{item.stockSize}
													<span className="text-xs font-light text-slate-400">
														Adet
													</span>
												</div>
											</div>
										)
									)}
							</div>
						</Widget>
					),
				},
				{
					id: 'siparis-ozeti',
					name: 'Sipariş Özeti',
					content: (
						<div className="grid h-[360px] grid-cols-1 gap-4">
							<div className="flex items-center gap-4 rounded-lg p-6 shadow bg-white">
								<div className="overflow-hidden whitespace-nowrap">
									<h6 className="mb-1 truncate text-sm text-slate-400">
										Toplam Sipariş
									</h6>
									<div className="truncate text-3xl font-medium">
										{
											api.getDashboard.response.data
												?.orderDashboardDTO?.orderSize
										}
									</div>
								</div>
								<div className="ml-auto shrink-0">
									<Doughnut
										options={{
											maintainAspectRatio: false,
											plugins: {
												legend: {
													display: false,
												},
											},
										}}
										data={{
											labels: [
												'İptal Edilen',
												'Tamamlanan',
												'Kargoda',
												'Teslim Edilemedi',
												'Hazırlanıyor',
												'İade Edilen',
											],
											datasets: [
												{
													data: [
														api.getDashboard
															.response.data
															?.orderDashboardDTO
															?.canceledOrderSize ||
															0,
														api.getDashboard
															.response.data
															?.orderDashboardDTO
															?.deliveredOrderSize ||
															0,
														api.getDashboard
															.response.data
															?.orderDashboardDTO
															?.orderInCargoSize ||
															0,
														api.getDashboard
															.response.data
															?.orderDashboardDTO
															?.outForDeliverOrderSize ||
															0,
														api.getDashboard
															.response.data
															?.orderDashboardDTO
															?.preparingOrderSize ||
															0,
														api.getDashboard
															.response.data
															?.orderDashboardDTO
															?.returnedOrderSize ||
															0,
													],
													backgroundColor: [
														'#fecaca',
														'#bbf7d0',
														'#fef08a',
														'#fed7aa',
														'#d9f99d',
														'#e5e7eb',
													],
													borderWidth: 0,
												},
											],
										}}
										className="-my-12 h-32 w-32"
									/>
								</div>
							</div>
							<div className="flex items-center gap-4 rounded-lg p-6 shadow bg-white">
								<div className="overflow-hidden whitespace-nowrap">
									<h6 className="mb-1 truncate text-sm text-slate-400">
										Toplam Puan
									</h6>
									<div className="truncate text-3xl font-medium">
										{/* eslint-disable */}
										{api.getDashboard.response.data
											?.scoreDashboardDTO
											?.totalScoreSize &&
											api.getDashboard.response.data.scoreDashboardDTO.totalScoreSize.toLocaleString(
												'tr-TR'
											)}
										{/* eslint-enable */}
									</div>
								</div>
								<div className="ml-auto shrink-0">
									<IoTrophyOutline className="h-8 w-8 opacity-25" />
								</div>
							</div>
						</div>
					),
				},
				{
					id: 'bekleyen-siparisler',
					name: 'Bekleyen Siparişler',
					content: (
						<Widget
							title="Bekleyen Siparişler"
							value={
								api.getDashboard.response.data
									?.orderDashboardDTO?.preparingOrderSize || 0
							}
							icon={
								<IoCartOutline className="h-8 w-8 opacity-25" />
							}
						>
							<div className="-mt-6 flex grow flex-col gap-3 overflow-y-auto py-6 [mask-image:linear-gradient(to_bottom,transparent_0%,black_theme(spacing.6),black_calc(100%-theme(spacing.6)),transparent_100%)]">
								{api.getDashboard.response.data
									?.orderDashboardDTO?.orderImageDTOList &&
									Array.isArray(
										api.getDashboard.response.data
											.orderDashboardDTO.orderImageDTOList
									) &&
									api.getDashboard.response.data.orderDashboardDTO.orderImageDTOList.map(
										(item, index) => (
											<div className="flex items-center justify-between gap-6 border-b-2 pb-3 border-slate-100" key={index}>
												<Thumbnails
													max={5}
													images={[
														...item.orderProductImageList.map(
															(image) => ({
																url: image,
															})
														),
													]}
													className="[&_img]:h-9 [&_img]:w-9 [&_img]:p-0 [&>div:not(:first-child)]:-ml-4"
												/>
												<div className="text-right text-sm">
													<Time
														dateBegin={moment(
															item.orderDate
														).format('DD.MM.YYYY')}
														timeBegin={moment(
															item.orderDate
														).format('HH:mm:ss')}
													/>
												</div>
											</div>
										)
									)}
							</div>
						</Widget>
					),
				},
				/*
				{
					id: 'sms-ozeti',
					name: 'SMS Özeti',
					content: (
						<div className="grid h-[360px] grid-cols-1 gap-4">
							<div className="flex items-center gap-4 rounded-lg p-6 shadow bg-white">
								<div className="overflow-hidden whitespace-nowrap">
									<h6 className="mb-1 truncate text-sm text-slate-400">
										Toplam SMS
									</h6>
									<div className="truncate text-3xl font-medium">
										3,671
									</div>
								</div>
								<div className="ml-auto shrink-0">
									<Button
										size="xs"
										variant="secondary-outline"
										className="inline-flex"
									>
										Yeni Gönder
									</Button>
								</div>
							</div>
							<div className="flex items-center justify-around gap-4">
								<div className="flex h-full grow items-center gap-4 rounded-lg p-6 shadow bg-white">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Başarılı Gönderim
										</h6>
										<div className="truncate text-3xl font-medium text-green-400">
											3,660
										</div>
									</div>
								</div>
								<div className="flex h-full grow items-center gap-4 rounded-lg p-6 shadow bg-white">
									<div className="overflow-hidden whitespace-nowrap">
										<h6 className="mb-1 truncate text-sm text-slate-400">
											Başarısız Gönderim
										</h6>
										<div className="truncate text-3xl font-medium text-red-400">
											11
										</div>
									</div>
								</div>
							</div>
						</div>
					),
				},
				*/
			]);
		}
	}, [api.getDashboard.response]);

	return (
		<Page>
			<Page.Header header="Dashboard" title="Anasayfa" />
			<Page.Body loading={false} show>
				<Page.Dashboard items={dashboardItems} size="lg" />
			</Page.Body>
			<Page.Footer />
		</Page>
	);
};

export default Dashboard;
