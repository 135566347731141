import React from 'react';

const Section = ({ children, title, className }) => (
	<div className={`mb-6 ${className || ''}`}>
		<h6 className="-mx-6 mb-6 px-6 py-3 text-xs font-medium uppercase text-blue-500 bg-blue-50">
			{title}
		</h6>
		<div className="flex flex-col gap-3">{children}</div>
	</div>
);

export default Section;
