import React from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Button,
	Input,
	Modal,
	Page,
	Select,
	Switch,
	Table,
} from '../../components';
import { useAxios } from '../../hooks';

const StaticPageCategories = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();

	const {
		register: registerSaveStaticPageCategory,
		setValue: setValueSaveStaticPageCategory,
		handleSubmit: handleSubmitSaveStaticPageCategory,
		reset: resetSaveStaticPageCategory,
		formState: { errors: errorsSaveStaticPageCategory },
	} = useForm();

	const api = {
		getStaticPageCategories: useAxios(),
		saveStaticPageCategory: useAxios(),
		removeStaticPageCategory: useAxios(),
	};

	const [staticPageCategories, setStaticPageCategories] = React.useState([]);

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getStaticPageCategories.response?.data) {
			setStaticPageCategories(api.getStaticPageCategories.response.data);
		}
	}, [api.getStaticPageCategories.response?.data]);

	/* getStaticPageCategories */

	const getStaticPageCategories = () => {
		api.getStaticPageCategories.fetch({
			method: 'GET',
			url: '/employee/admin/get/all/footerAboutUs',
		});
	};

	React.useEffect(() => {
		if (api.getStaticPageCategories.response) {
			console.log(api.getStaticPageCategories.response);
		}
	}, [api.getStaticPageCategories.response]);

	React.useEffect(() => {
		if (api.getStaticPageCategories.error) {
			toast.error(api.getStaticPageCategories.error.message);
		}
	}, [api.getStaticPageCategories.error]);

	React.useEffect(() => {
		getStaticPageCategories();
	}, []);

	/* saveStaticPageCategory */

	const saveStaticPageCategory = async (data) => {
		const body = {
			...data,
			...(data.id ? { id: parseInt(data.id, 10) } : null),
			active: !!(data.active === 'true' || data.active === true),
		};

		api.saveStaticPageCategory.fetch({
			method: 'POST',
			url: '/employee/admin/saveFooterAboutUs',
			data: body,
		});
	};

	React.useEffect(() => {
		if (api.saveStaticPageCategory.response) {
			resetSaveStaticPageCategory();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getStaticPageCategories();
		}
	}, [api.saveStaticPageCategory.response]);

	React.useEffect(() => {
		if (api.saveStaticPageCategory.error) {
			toast.error(api.saveStaticPageCategory.error.message);
		}
	}, [api.saveStaticPageCategory.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getStaticPageCategories.response) {
			const { id } = showEdit;
			const item =
				api.getStaticPageCategories.response.data.content[
					api.getStaticPageCategories.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ title: item.title },
				{ slug: item.slug },
				{ active: item.type },
			];

			values.forEach((value) => {
				setValueSaveStaticPageCategory(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});
		}
	}, [showEdit.id && api.getStaticPageCategories.response]);

	/* removeStaticPageCategory */

	const removeStaticPageCategory = (id) => {
		api.removeStaticPageCategory.fetch({
			method: 'POST',
			url: '/employee/admin/deleteFooterAboutUs',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeStaticPageCategory.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getStaticPageCategories();
		}
	}, [api.removeStaticPageCategory.response]);

	React.useEffect(() => {
		if (api.removeStaticPageCategory.error) {
			toast.error(api.removeStaticPageCategory.error.message);
		}
	}, [api.removeStaticPageCategory.error]);

	/* return */

	return (
		<Page>
			<Page.Header header="Metinler" title="Tüm Sayfa Açıklamaları">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getStaticPageCategories.loading}
				show={
					!api.getStaticPageCategories.loading &&
					api.getStaticPageCategories.response
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Başlık', 'Slug', 'Durum', '']}
						/>
						<Table.Body>
							{staticPageCategories?.content?.length &&
								staticPageCategories?.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<div className="grid gap-1">
													<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
														{item.title}
													</h6>
												</div>
											</Table.Column>
											<Table.Column>
												<div className="grid gap-1">
													<h6 className="truncate whitespace-nowrap text-sm font-normal text-slate-700">
														{item.slug}
													</h6>
												</div>
											</Table.Column>
											<Table.Column>
												<Switch
													id={item.id}
													active={item.active}
													list={
														staticPageCategories.content
													}
													onChange={
														setStaticPageCategories
													}
													saveList={
														saveStaticPageCategory
													}
													customPopUpText={false}
												/>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getStaticPageCategories.loading ||
					api.saveStaticPageCategory.loading ||
					api.removeStaticPageCategory.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveStaticPageCategory();
				}}
			>
				<form
					onSubmit={handleSubmitSaveStaticPageCategory(
						saveStaticPageCategory
					)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						<Input
							type="text"
							name="title"
							label="Başlık"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveStaticPageCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveStaticPageCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="text"
							name="slug"
							label="Slug"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveStaticPageCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveStaticPageCategory}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveStaticPageCategory}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={registerSaveStaticPageCategory}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveStaticPageCategory}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeStaticPageCategory(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveStaticPageCategory)
									.length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default StaticPageCategories;
