import React from 'react';

const Item = ({ image, title, description, className, size = 'md' }) => (
	<div className={`flex items-center gap-6 ${className || ''}`}>
		{image ? (
			<img
				src={image}
				alt={title}
				className={`inline-block aspect-square rounded-lg object-cover shadow bg-slate-50
					${size === 'sm' ? 'h-9 w-9' : 'h-12 w-12'}
				`}
				width={size === 'sm' ? 36 : 48}
				height={size === 'sm' ? 36 : 48}
			/>
		) : (
			title && (
				<div
					className={`flex shrink-0 items-center justify-center rounded-full text-center font-medium uppercase text-white bg-blue-500
					${size === 'sm' ? 'h-9 w-9 text-sm' : 'h-12 w-12 text-base'}
				`}
				>
					{title
						.split(' ')
						.slice(0, 2)
						.map((string) => string.charAt(0).toUpperCase())}
				</div>
			)
		)}
		<div className="grid gap-1">
			<h6
				className={`truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700 
					${size === 'sm' ? 'text-sm' : ''}
				`}
			>
				{title}
			</h6>
			{description && (
				<p
					className={`truncate whitespace-nowrap text-slate-400
					${size === 'sm' ? 'text-xs' : ''}
				`}
				>
					{description}
				</p>
			)}
		</div>
	</div>
);

export default Item;
