import React from 'react';

const Column = ({ children, className }) => (
	<td
		className={`whitespace-nowrap px-4 py-4 text-left text-sm ${
			className || ''
		}`}
	>
		{children}
	</td>
);

export default Column;
