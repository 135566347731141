import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';

import { useAxios } from '../../hooks';
import {
	Badge,
	Button,
	Input,
	Modal,
	Page,
	Select,
	Switch,
	Table,
	TextArea,
	Time,
} from '../../components';

const Forums = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [showComments, setShowComments] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveForum,
		watch: watchSaveForum,
		setValue: setValueSaveForum,
		handleSubmit: handleSubmitSaveForum,
		reset: resetSaveForum,
		formState: { errors: errorsSaveForum },
	} = useForm();

	const api = {
		getForums: useAxios(),
		saveForum: useAxios(),
		removeForum: useAxios(),
		getComments: useAxios(),
		saveComment: useAxios(),
	};

	const [forums, setForums] = React.useState([]); // Listeyi state olarak tut

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getForums.response?.data) {
			setForums(api.getForums.response.data);
		}
	}, [api.getForums.response?.data]);

	/* get forums */

	const getForums = () => {
		api.getForums.fetch({
			method: 'GET',
			url: '/forum/admin/get/all/topics/?page=0&size=9999',
		});
	};

	React.useEffect(() => {
		getForums();
	}, []);

	React.useEffect(() => {
		if (api.getForums.response) {
			console.log(api.getForums.response);
		}
	}, [api.getForums.response]);

	React.useEffect(() => {
		if (api.getForums.error) {
			toast.error(api.getForums.error.message);
		}
	}, [api.getForums.error]);

	/* save forum */

	const saveForum = (data) => {
		const body = {
			...data,
			active: !!(data.active === 'true' || data.active === true),
			beginDate:
				data.beginDate !== ''
					? moment.utc(data.beginDate).format()
					: '',
			endDate:
				data.endDate !== '' ? moment.utc(data.endDate).format() : '',
			isCompetition: data.isCompetition === 'true',
			winnerRankCount: data.winnerRankCount
				? parseInt(data.winnerRankCount, 10)
				: 0,
			maxCommentCount: data.winnerRankCount
				? parseInt(data.maxCommentCount, 10)
				: 0,
		};

		if (data.id) {
			body.topicId = parseInt(data.id, 10);
			delete body.id;
		}

		api.saveForum.fetch({
			method: 'POST',
			url: '/forum/admin/save/topic',
			data: body,
		});
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (api.saveForum.response) {
			resetSaveForum();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getForums();
		}
	}, [api.saveForum.response]);

	React.useEffect(() => {
		if (api.saveForum.error) {
			toast.error(api.saveForum.error.message);
		}
	}, [api.saveForum.error]);

	React.useEffect(() => {
		if (showEdit.id && api.getForums.response) {
			const { id } = showEdit;
			const item =
				api.getForums.response.data.content[
					api.getForums.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ title: item.title },
				{ description: item.description },
				{
					beginDate: moment(item.beginDate).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ endDate: moment(item.endDate).format('YYYY-MM-DD HH:mm:ss') },
				{ isCompetition: item.isCompetition },
				{ winnerRankCount: item.winnerRankCount },
				{ maxCommentCount: item.maxCommentCount },
				{ active: item.active },
			];

			values.forEach((value) => {
				setValueSaveForum(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			// TODO:
			// commentDTOList
			// employeeFirmDTO
			// topicImageDTOList

			// - yarisma mi degil mi datasi dogru gelmiyor
		}
	}, [showEdit, api.getForums.response]);

	/* remove forum */

	const removeForum = (id) => {
		api.removeForum.fetch({
			method: 'POST',
			url: `/forum/admin/delete/topic?topicId=${id}`,
		});
	};

	React.useEffect(() => {
		if (api.removeForum.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getForums();
		}
	}, [api.removeForum.response]);

	React.useEffect(() => {
		if (api.removeForum.error) {
			toast.error(api.removeForum.error.message);
		}
	}, [api.removeForum.error]);

	/* get comments */

	const getComments = (data) => {
		api.getComments.fetch({
			method: 'POST',
			url: '/forum/admin/get/all/comments/for/topic',
			data: {
				topicId: data.topicId,
			},
		});
	};

	React.useEffect(() => {
		if (api.getComments.error) {
			toast.error(api.getComments.error.message);
		}
	}, [api.getComments.error]);

	/* save comment */

	const saveComment = (data) => {
		if (data.id) {
			api.saveComment.fetch({
				method: 'POST',
				url: '/forum/admin/change/comment/status',
				data: {
					commentId: data.id,
					commentStatus: data.status,
				},
			});
		}
	};

	React.useEffect(() => {
		if (api.saveComment.response) {
			setShowComments(false);
			toast.success('Kaydedildi.');
		}
	}, [api.saveComment.response]);

	React.useEffect(() => {
		if (api.saveComment.error) {
			toast.error(api.saveComment.error.message);
		}
	}, [api.saveComment.error]);

	return (
		<Page>
			<Page.Header header="Forum" title="Tüm Konular">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getForums.loading}
				show={!api.getForums.loading && api.getForums.response}
			>
				<Page.Content>
					<Table className="!table-fixed">
						<Table.Head
							data={[
								'Id',
								'Forum',
								'Görüntülenme',
								'Beğeni',

								'Yarışma',
								'Yorum Limiti',

								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{forums?.content?.length &&
								forums.content.map((item, index) => (
									<Table.Row key={index}>
										<Table.Column>
											<span className="whitespace-nowrap text-xs font-normal text-slate-400">
												{item.id}
											</span>
										</Table.Column>
										<Table.Column>
											<div className="grid gap-1">
												<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
													{item.title}
												</h6>
												<p className="truncate whitespace-nowrap text-xs text-slate-400">
													{item.description}
												</p>
											</div>
										</Table.Column>
										<Table.Column className="text-base font-medium">
											{item.views}
										</Table.Column>
										<Table.Column className="text-base font-medium">
											{item.likes}
										</Table.Column>
										<Table.Column>
											<div className="text-base font-medium">
												{item.isCompetition ? (
													<BsCheckCircleFill className="h-4 w-4 text-green-500" />
												) : (
													<BsFillXCircleFill className="h-4 w-4 text-red-500" />
												)}
											</div>
											{item.winnerRankCount && (
												<div className="mt-1 text-xs text-slate-400">
													Oran: {item.winnerRankCount}
												</div>
											)}
										</Table.Column>
										<Table.Column className="truncate whitespace-nowrap text-xs">
											{item.maxCommentCount
												? item.maxCommentCount
												: 'Limitsiz'}
										</Table.Column>
										<Table.Column>
											<Time
												dateBegin={moment(
													item.beginDate
												).format('DD.MM.YYYY')}
												timeBegin={moment(
													item.beginDate
												).format('HH:mm:ss')}
												dateEnd={moment(
													item.endDate
												).format('DD.MM.YYYY')}
												timeEnd={moment(
													item.endDate
												).format('HH:mm:ss')}
											/>
										</Table.Column>
										<Table.Column>
											<Switch
												id={item.id}
												active={item.active}
												list={forums.content}
												onChange={setForums}
												saveList={saveForum}
												customPopUpText={false}
											/>
										</Table.Column>
										<Table.Column className="text-right [&_button]:w-20">
											<div className="flex flex-col items-end justify-center gap-2">
												<Button
													size="xs"
													variant="primary-outline"
													className="inline-flex"
													onClick={() => {
														getComments({
															topicId: item.id,
														});
														setShowComments({
															id: item.id,
														});
													}}
												>
													Yorumlar
												</Button>
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</div>
										</Table.Column>
									</Table.Row>
								))}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getForums.loading ||
					api.saveForum.loading ||
					api.removeForum.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveForum();
				}}
			>
				<form onSubmit={handleSubmitSaveForum(saveForum)} noValidate>
					<div className="flex flex-col gap-4 p-6">
						<Input
							type="text"
							name="title"
							label="Başlık"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveForum}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveForum}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<TextArea
							name="description"
							label="Açıklama"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveForum}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveForum}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="beginDate"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now()).format(
								'YYYY-MM-DD HH:mm'
							)}
							register={registerSaveForum}
							errors={errorsSaveForum}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="endDate"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now())
								.add(1, 'days')
								.format('YYYY-MM-DD HH:mm')}
							register={registerSaveForum}
							errors={errorsSaveForum}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="isCompetition"
							label="Yarışma"
							grid
							options={[
								{
									name: 'Hayır',
									value: false,
								},
								{
									name: 'Evet',
									value: true,
								},
							]}
							defaultValue={false}
							register={registerSaveForum}
							// validation={{
							// 	required: 'Bu alan zorunludur.',
							// }}
							errors={errorsSaveForum}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						{watchSaveForum('isCompetition') === 'true' && (
							<>
								<Input
									type="number"
									name="winnerRankCount"
									label="Oran"
									grid
									placeholder="Lütfen giriniz."
									register={registerSaveForum}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveForum}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
								<Input
									type="number"
									name="maxCommentCount"
									label="Yorum Limiti"
									grid
									placeholder="Lütfen giriniz."
									register={registerSaveForum}
									validation={{
										required: 'Bu alan zorunludur.',
									}}
									errors={errorsSaveForum}
									size="lg"
									className={{ input: '!text-sm' }}
								/>
							</>
						)}
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveForum}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveForum}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveForum}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeForum(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveForum).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>

			<Modal
				show={!!showComments.id}
				loading={api.getComments.loading}
				size="xl"
				title="Yorumlar"
				onClose={() => {
					setShowComments(false);
				}}
			>
				<div className="flex flex-col gap-4 p-6">
					{api.getComments.response?.data?.content?.length ? (
						<Table className="!table-fixed">
							<Table.Head
								data={['Id', 'Yorum', 'Tarih', 'Durum', '']}
							/>
							<Table.Body>
								{api.getComments.response.data.content.map(
									(item) => (
										<Table.Row key={item.id}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column className="w-1/3 !whitespace-normal text-xs capitalize text-slate-400">
												<h6 className="mb-1 font-normal">
													{item.username}
												</h6>
												<p>{item.description}</p>
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.createDate
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.createDate
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												<Badge
													variant={
														{
															APPROVED: 'success',
															DENIED: 'danger',
															WAITING: 'warning',
														}[item.commentStatus]
													}
												>
													{
														{
															APPROVED:
																'Onaylandı',
															DENIED: 'Reddedildi',
															WAITING:
																'Onay Bekliyor',
														}[item.commentStatus]
													}
												</Badge>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<div className="flex flex-col items-end justify-center gap-2">
													{item.commentStatus ===
														'APPROVED' && (
														<Button
															size="xs"
															variant="danger-outline"
															className="inline-flex"
															onClick={() => {
																saveComment({
																	id: item.id,
																	status: 'DENIED',
																});
															}}
														>
															Reddet
														</Button>
													)}
													{(item.commentStatus ===
														'DENIED' ||
														item.commentStatus ===
															'WAITING') && (
														<Button
															size="xs"
															variant="primary"
															className="inline-flex"
															onClick={() => {
																saveComment({
																	id: item.id,
																	status: 'APPROVED',
																});
															}}
														>
															Onayla
														</Button>
													)}
												</div>
											</Table.Column>
										</Table.Row>
									)
								)}
							</Table.Body>
						</Table>
					) : (
						<div className="rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
							Yorum bulunamadı
						</div>
					)}
				</div>
			</Modal>
		</Page>
	);
};

export default Forums;
