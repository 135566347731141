import React from 'react';
import { IoAlertCircle } from 'react-icons/io5';

const TextArea = ({
	id,
	name,
	label,
	grid,
	register,
	validation,
	errors,
	defaultValue,
	className,
	size = 'md',
	placeholder,
}) => {
	const sizes = {
		xs: 'p-2 text-xs',
		sm: 'p-2.5 text-sm',
		md: 'p-3 text-sm',
		lg: 'p-3.5 text-base',
		xl: 'p-4 text-lg',
	};

	return (
		<div
			className={`group 
				${errors && errors[name] ? 'invalid' : ''} 
				${(className && className.group) || ''}
			`}
		>
			<div className={grid ? 'grid grid-cols-3 items-center' : ''}>
				{label && (
					<label
						className={`flex gap-1 text-sm font-normal text-slate-700 group-[.invalid]:!text-red-500 
							${grid ? 'col-span-1' : 'mb-2'}
							${(className && className.label) || ''}
						`}
					>
						{label}
					</label>
				)}
				<textarea
					name={name}
					id={id || name}
					placeholder={placeholder}
					defaultValue={defaultValue}
					rows="4"
					className={`relative z-10 rounded-lg border-2 placeholder-slate-400 outline-none transition duration-300 text-slate-500 bg-slate-50 border-slate-200 focus:ring-blue-500 focus:border-blue-500 group-[.invalid]:text-red-500 group-[.invalid]:border-red-500 
						${grid ? 'col-span-2' : 'w-full'}
						${sizes[size]} 
						${(className && className.input) || ''}
					`}
					{...(register && register(name, validation && validation))}
				/>
			</div>
			{errors && errors[name] && (
				<div
					className={`
						relative -top-2 inline-flex gap-1 rounded-b-lg p-2 pt-4 text-sm text-white bg-red-500
						${grid ? 'left-1/3 w-2/3' : 'w-full'}
					`}
				>
					<IoAlertCircle className="h-5 w-5 shrink-0" />
					{errors[name].message}
				</div>
			)}
		</div>
	);
};

export default TextArea;
