import React from 'react';
import Table from '../Table';
import Modal from '../Modal';
import Button from '../Button';

const Switch = ({ id, active, list, onChange, saveList, customPopUpText }) => {
	const [showConfirmModal, setShowConfirmModal] = React.useState(false);

	// Aktifliği değiştirme fonksiyonu
	const handleSwitchChange = () => {
		console.log('Switch değiştirildi');
		setShowConfirmModal(true);
	};

	const updateStatus = () => {
		// products listesini güncelle
		const updatedList = list?.map((item) => {
			console.log(item);
			if (item.id === id) {
				const updatedItem = { ...item, active: !item.active };
				console.log(updatedItem);
				saveList(updatedItem, 'switch'); // Sadece değiştirilen ürünü kaydet
				return updatedItem;
			}
			return item;
		});

		// Parent component'a güncel listeyi gönder
		onChange(updatedList);
		setShowConfirmModal(false);
	};

	return (
		<>
			<div className="flex">
				<Table>
					<Table.Body>
						<Table.Row>
							<Table.Column>
								<label className="relative inline-flex items-center cursor-pointer">
									<input
										type="checkbox"
										className="sr-only peer"
										checked={active || false}
										onChange={handleSwitchChange}
									/>
									<div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-green-500 peer-focus:ring-2 peer-focus:ring-green-300" />
									<div
										className={`absolute w-5 h-5 bg-white border rounded-full transition-transform ${
											active
												? 'translate-x-5 border-green-500'
												: 'translate-x-0 border-gray-300'
										}`}
									/>
								</label>
							</Table.Column>
							<Table.Column>
								{active ? (
									<span className="text-green-500">
										Aktif
									</span>
								) : (
									<span className="text-red-500">Pasif</span>
								)}
							</Table.Column>
						</Table.Row>
					</Table.Body>
				</Table>
			</div>

			<Modal
				show={showConfirmModal}
				onClose={() => setShowConfirmModal(false)}
				size="xl"
			>
				<div className="p-4">
					<p className="mb-4 text-slate-700 whitespace-normal break-words text-center">
						{(() => {
							if (customPopUpText === true) {
								return active
									? 'Katalogtan ürün alımında, Puan artı Para fonksiyonu ile kredi kartından tahsilat pasife çekilecektir. Devam etmek istiyor musunuz?'
									: 'Katalogtan ürün alımında, Puan artı Para fonksiyonu ile kredi kartından tahsilat aktive edilecektir. Devam etmek istiyor musunuz?';
							}
							return active
								? 'Seçili kayıt pasif edilecektir. Devam etmek istiyor musunuz?'
								: 'Seçili kayıt aktif edilecektir. Devam etmek istiyor musunuz?';
						})()}
					</p>
					<div className="flex justify-end space-x-4">
						<Button
							className="px-4 py-2 bg-gray-200 rounded-md mr-2"
							onClick={() => setShowConfirmModal(false)}
						>
							Hayır
						</Button>
						<Button
							className="px-4 py-2 bg-green-500 text-white rounded-md"
							onClick={updateStatus}
						>
							Evet
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Switch;
