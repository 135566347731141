import React from 'react';
import { Transition } from '@headlessui/react';
import { BsFolderX } from 'react-icons/bs';
import { CgSpinner } from 'react-icons/cg';

const Body = ({ children, loading, show }) => (
	<>
		{loading && (
			<div className="flex grow items-center justify-center">
				<CgSpinner className="h-8 w-8 animate-spin text-blue-500" />
			</div>
		)}

		{show ? (
			<Transition
				show
				appear
				className="flex grow flex-col gap-8"
				enter="transition-opacity duration-300"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-200"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				{children}
			</Transition>
		) : (
			!loading && (
				<Transition
					show
					appear
					className="flex grow flex-col items-center justify-center gap-4"
					enter="transition-opacity duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<BsFolderX className="h-24 w-24 opacity-25" />
					<h3 className="text-base font-normal">Sonuç bulunamadı.</h3>
				</Transition>
			)
		)}
	</>
);

export default Body;
