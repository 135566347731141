import React from 'react';

const Head = ({ data }) => (
	<thead>
		<tr>
			{data.map((item, index, { length }) => (
				<th
					key={index}
					scope="col"
					className={`
                        whitespace-nowrap px-4 py-3 text-left text-xs font-medium uppercase text-slate-500 bg-slate-100 
                        ${index === 0 && 'rounded-l-lg'}
                        ${index === length - 1 && 'rounded-r-lg'}`}
				>
					{item}
				</th>
			))}
		</tr>
	</thead>
);

export default Head;
