import React from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Switch } from '@headlessui/react';
import { BiChevronRight } from 'react-icons/bi';
import { MdDragIndicator } from 'react-icons/md';

import Context from '../../Context';
import Modal from '../../Modal';
import Button from '../../Button';

const Dashboard = ({ items, size }) => {
	const [context, setContext] = React.useContext(Context);
	const [dashboards, setDashboards] = React.useState();
	const [sorts, setSorts] = React.useState();
	const [switches, setSwitches] = React.useState({});
	const { pathname } = useLocation();
	const { state } = context;

	const onDrag = (droppedItem) => {
		if (!droppedItem.destination) return;
		const updated = [...sorts];
		const [reorderedItem] = updated.splice(droppedItem.source.index, 1);
		updated.splice(droppedItem.destination.index, 0, reorderedItem);
		setSorts(updated);
	};

	const onSwitch = (index) => {
		setSwitches({
			...switches,
			[sorts[index].id]:
				switches[sorts[index].id] !== undefined
					? !switches[sorts[index].id]
					: !sorts[index].active,
		});
	};

	const onSave = () => {
		const sortsSaved = [...sorts];
		Object.entries(switches).forEach((updated) => {
			sortsSaved[sorts.findIndex((item) => item.id === updated[0])] = {
				id: updated[0],
				active: updated[1],
			};
		});
		localStorage.setItem(
			'dashboards',
			JSON.stringify({
				...JSON.parse(localStorage.getItem('dashboards')),
				[pathname]: sortsSaved,
			})
		);
		setContext({
			...context,
			state: {
				...state,
				showSettings: false,
			},
		});
		setSwitches({});
		setDashboards(sortsSaved);
		toast.success('Kaydedildi.');
	};

	React.useEffect(() => {
		if (items) {
			setContext({
				...context,
				state: {
					...state,
					showSettings: false,
				},
			});
			setDashboards(
				(() => {
					const storedDashboards = localStorage.getItem('dashboards');
					return storedDashboards &&
						JSON.parse(storedDashboards)[pathname]
						? JSON.parse(storedDashboards)[pathname]
						: [
							...items.map((item) => ({
								id: item.id,
								active: true,
							})),
						];
				})()
			);
		}
	}, [items]);

	React.useEffect(() => {
		if (state.showSettings) {
			setSorts(dashboards);
		}
	}, [state.showSettings]);

	return (
		dashboards && (
			<section>
				{size === 'lg' ? (
					<div className="group grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
						{dashboards.map((order) => {
							const { id, content } =
								items[
									items.findIndex(
										(item) => item.id === order.id
									)
								] || {};
							return (
								id &&
								order.active && (
									<React.Fragment key={id}>
										{content}
									</React.Fragment>
								)
							);
						})}
					</div>
				) : (
					<div className="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4">
						{dashboards.map((order) => {
							const { id, name, value, icon, onClick } =
								items[
									items.findIndex(
										(item) => item.id === order.id
									)
								] || {};
							return (
								id &&
								order.active && (
									<div
										key={id}
										className="group relative flex items-center gap-4 overflow-hidden rounded-lg px-6 py-4 shadow bg-white"
									>
										<div className="overflow-hidden whitespace-nowrap">
											<h6 className="mb-1 truncate text-sm text-slate-400">
												{name}
											</h6>
											<div className="truncate text-3xl font-medium">
												{value}
											</div>
										</div>
										{icon && (
											<div className="ml-auto shrink-0">
												{icon}
											</div>
										)}
										{onClick && (
											<button
												type="button"
												className="absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center gap-1 p-4 text-center text-sm font-normal opacity-0 transition duration-300 bg-white text-blue-600 group-hover:opacity-90"
												onClick={onClick}
											>
												Görüntüle
												<BiChevronRight className="h-4 w-4 shrink-0" />
											</button>
										)}
									</div>
								)
							);
						})}
					</div>
				)}

				<Modal
					show={state.showSettings === true}
					title="Ayarlar"
					onClose={() => {
						setContext({
							...context,
							state: {
								...state,
								showSettings: false,
							},
						});
						setSorts(dashboards);
						setSwitches({});
					}}
				>
					<div className="p-4">
						<DragDropContext onDragEnd={onDrag}>
							<Droppable droppableId="dashboard-sorts">
								{(droppableProvided) => (
									<div
										{...droppableProvided.droppableProps}
										ref={droppableProvided.innerRef}
									>
										<div className="flex select-none flex-col">
											{sorts &&
												sorts.map((item, index) => (
													<Draggable
														key={item.id}
														draggableId={item.id}
														index={index}
													>
														{(
															draggableProvided
														) => (
															<div
																ref={
																	draggableProvided.innerRef
																}
																{...draggableProvided.dragHandleProps}
																{...draggableProvided.draggableProps}
																className="mb-2 flex items-center rounded-lg border-2 p-3 text-sm font-normal transition-colors duration-300 bg-white border-slate-200 hover:bg-slate-50"
															>
																<MdDragIndicator className="mr-2 h-4 w-4 opacity-50" />
																<div>
																	{
																		items[
																			items.findIndex(
																				(
																					founded
																				) =>
																					founded.id ===
																					item.id
																			)
																		].name
																	}
																</div>

																<Switch
																	defaultChecked={
																		item.active
																	}
																	as={
																		React.Fragment
																	}
																	onChange={() =>
																		onSwitch(
																			index
																		)
																	}
																>
																	{({
																		checked,
																	}) => (
																		<button
																			type="button"
																			className={`${
																				checked
																					? 'bg-green-500'
																					: 'bg-gray-200'
																			} relative ml-auto inline-flex h-6 w-11 items-center rounded-full`}
																		>
																			<span
																				className={`${
																					checked
																						? 'translate-x-6'
																						: 'translate-x-1'
																				} inline-block h-4 w-4 transform rounded-full transition duration-300 bg-white`}
																			/>
																		</button>
																	)}
																</Switch>
															</div>
														)}
													</Draggable>
												))}
											{droppableProvided.placeholder}
										</div>
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						<Button variant="primary" size="md" onClick={onSave}>
							Kaydet
						</Button>
					</div>
				</Modal>
			</section>
		)
	);
};

export default Dashboard;
