import React from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useAxios } from '../../hooks';
import { Input, Button, Layout } from '../../components';

const ResetPassword = () => {
	const {
		register: registerResetPassword,
		handleSubmit: handleSubmitResetPassword,
		reset: resetResetPassword,
		formState: { errors: errorsResetPassword },
	} = useForm();

	const {
		loading: loadingResetPassword,
		response: responseResetPassword,
		error: errorResetPassword,
		fetch: fetchResetPassword,
	} = useAxios();

	const onSubmit = (data) => {
		fetchResetPassword({
			method: 'POST',
			// url: '/auth/public/login',
			data,
		});
	};

	React.useEffect(() => {
		if (responseResetPassword) {
			console.log(responseResetPassword);
		}
	}, [responseResetPassword]);

	React.useEffect(() => {
		if (errorResetPassword) {
			resetResetPassword();
			toast.error(errorResetPassword.message);
		}
	}, [errorResetPassword]);

	return (
		<Layout.Public>
			<form
				className="w-full sm:w-[360px] sm:max-w-full"
				onSubmit={handleSubmitResetPassword(onSubmit)}
				noValidate
			>
				<div className="mb-12 mt-6 text-center">
					<h2 className="text-xl font-medium !leading-tight text-slate-700">
						Parolamı Unuttum.
					</h2>
				</div>

				{/* username */}
				<Input
					type="text"
					name="username"
					label="Kullanıcı Adı"
					register={registerResetPassword}
					validation={{
						required: 'Bu alan zorunludur.',
					}}
					errors={errorsResetPassword}
					size="xl"
					className={{ input: '!text-sm' }}
				/>

				{/* submit */}
				<Button
					type="submit"
					loading={loadingResetPassword}
					disabled={
						Object.keys(errorsResetPassword).length > 0 ||
						loadingResetPassword
					}
					size="xl"
					className="mt-6 w-full !text-sm"
				>
					Kod Gönder
				</Button>

				<div className="mt-6 text-center text-sm">
					<NavLink
						to="/giris-yap"
						className="text-blue-700 hover:underline"
					>
						Giriş yap
					</NavLink>{' '}
					veya{' '}
					<NavLink
						to="/uye-ol"
						className="text-blue-700 hover:underline"
					>
						üye ol
					</NavLink>
				</div>
			</form>
		</Layout.Public>
	);
};

export default ResetPassword;
