import React from 'react';

const Row = ({ children, className }) => (
	<tr
		className={`group border-b-2 transition-all bg-white border-slate-100 hover:bg-slate-50 ${
			className || ''
		}`}
	>
		{children}
	</tr>
);

export default Row;
