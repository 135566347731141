import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BsFillXCircleFill } from 'react-icons/bs';
import {
	Button,
	Context,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Switch,
	Table,
	Time,
} from '../../components';
import { useAxios } from '../../hooks';

const GameWordPuzzle = () => {
	const [context] = React.useContext(Context);
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [choices, setChoices] = React.useState([]);
	const [trueChoice, setTrueChoice] = React.useState(0);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveGame,
		watch: watchSaveGame,
		setValue: setValueSaveGame,
		handleSubmit: handleSubmitSaveGame,
		reset: resetSaveGame,
		formState: { errors: errorsSaveGame },
	} = useForm();

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrands: useAxios(),
		getBrandStyles: useAxios(),
	};

	const [games, setGames] = React.useState([]); // Listeyi state olarak tut

	// API'den veriyi çek ve state'e ata
	React.useEffect(() => {
		if (api.getGames.response?.data) {
			setGames(api.getGames.response.data);
		}
	}, [api.getGames.response?.data]);

	const getBrands = () => {
		api.getBrands.fetch({
			method: 'GET',
			url: '/brand/admin/brandList',
		});
	};

	React.useEffect(() => {
		getBrands();
	}, []);

	const getBrandStyles = (id) => {
		api.getBrandStyles.fetch({
			method: 'GET',
			url: `/brand/admin/brandStyleList/${id}`,
		});
	};

	React.useEffect(() => {
		if (api.getBrandStyles.error) {
			toast.error(api.getBrandStyles.error.message);
		}
	}, [api.getBrandStyles.error]);

	React.useEffect(() => {
		if (watchSaveGame('brandId')) {
			getBrandStyles(parseInt(watchSaveGame('brandId'), 10));
		}
	}, [watchSaveGame('brandId')]);

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: '/game/admin/getHangmanList?page=0&size=9999',
		});
	};

	React.useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	React.useEffect(() => {
		getGames();
	}, []);

	/* saveGame */

	const saveGame = async (data) => {
		const formData = new FormData();

		formData.append(
			'saveHangmanDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				...(data.brandId
					? { brandId: parseInt(data.brandId, 10) }
					: null),
				...(data.brandStyleId
					? { brandStyleId: parseInt(data.brandStyleId, 10) }
					: null),
				...(choices.length && {
					scoreHuntChoiceDTOList: choices,
				}),
				...(currentImage.length &&
				currentImage[0] &&
				typeof currentImage[0] === 'string'
					? { contents: [currentImage[0]] }
					: null),
			})
		);

		if (
			currentImage.length &&
			currentImage[0] &&
			currentImage[0] instanceof File
		) {
			formData.append('icon', currentImage[0]);
		}

		api.saveGame.fetch({
			method: 'POST',
			url: '/game/admin/saveHangman',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'X-Tenant': localStorage.getItem('tenant')
					? localStorage.getItem('tenant')
					: context?.signed?.data?.tenantName,
			},
		});
	};

	React.useEffect(() => {
		if (api.saveGame.response) {
			resetSaveGame();
			setCurrentImage([]);
			setChoices([]);
			setTrueChoice(0);
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getGames();
		}
	}, [api.saveGame.response]);

	React.useEffect(() => {
		if (api.saveGame.error) {
			toast.error(api.saveGame.error.message);
		}
	}, [api.saveGame.error]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getGames.response) {
			const { id } = showEdit;
			const item =
				api.getGames.response.data.content[
					api.getGames.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ title: item.title },
				{ description: item.description },
				{ score: item.score },
				{ remainTime: item.remainTime },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
				{ buttonText: item.buttonText },
				{ inGameDescription: item.inGameDescription },
				{ backgroundPattern: item.backgroundPattern },
				{ trueAnswerText: item.trueAnswerText },
				{ wrongAnswerText: item.wrongAnswerText },
				{ timeoutAnswerText: item.timeoutAnswerText },
				{ questionTitle: item.questionTitle },
				{ questionDescription: item.questionDescription },
				{ numberOfTry: item.numberOfTry },
				{ numberOfLetterTry: item.numberOfLetterTry },
				{ correctWord: item.correctWord },
				{ question: item.question },
			];

			values.forEach((value) => {
				setValueSaveGame(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (item.hangmanChoiceDTOList) {
				const hangmanChoiceDTOList = [];
				item.hangmanChoiceDTOList.forEach((value, index) => {
					hangmanChoiceDTOList.push({
						id: value.id,
						description: value.description,
						isTrue: value.isTrue,
					});
					if (!trueChoice && item.isTrue) {
						setTrueChoice(index);
					}
				});
				setChoices(hangmanChoiceDTOList);
			}

			if (item.icon) {
				const images = [];
				images.push(item.icon);
				setCurrentImage(images);
			}
		}

		if (showEdit.id && api.getBrandStyles.response) {
			const { id } = showEdit;
			const item =
				api.getGames.response.data.content[
					api.getGames.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			setValueSaveGame('brandStyleId', item.brandStyleId, {
				shouldValidate: true,
			});
		}
	}, [showEdit.id && api.getGames.response && api.getBrands.response]);

	const handleAddChoice = () => {
		setChoices([
			...choices,
			{
				description: '',
				isTrue: false,
			},
		]);
	};

	const handleRemoveChoice = (index) => {
		const choicesTemp = [...choices];
		choicesTemp.splice(index, 1);
		setChoices([...choicesTemp]);
	};

	/* removeGame */

	const removeGame = (id) => {
		api.removeGame.fetch({
			method: 'POST',
			url: '/game/admin/hangman/delete',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeGame.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getGames();
		}
	}, [api.removeGame.response]);

	React.useEffect(() => {
		if (api.removeGame.error) {
			toast.error(api.removeGame.error.message);
		}
	}, [api.removeGame.error]);

	return (
		<Page>
			<Page.Header header="Oyunlar" title="Kelime Bulmaca">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getGames.loading}
				show={!api.getGames.loading && api.getGames.response}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={['Id', 'Oyun', 'Tarih', 'Durum', '']}
						/>
						<Table.Body>
							{games?.content?.length &&
								games?.content.map((item, index) => (
									<Table.Row key={index}>
										<Table.Column>
											<span className="whitespace-nowrap text-xs font-normal text-slate-400">
												{item.id}
											</span>
										</Table.Column>
										<Table.Column>
											<Item
												image={
													item.icon
														? item.icon
														: require('../../assets/logo-connection.png')
												}
												title={item.title}
												description={item.description}
												className="w-96 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
											/>
										</Table.Column>
										<Table.Column>
											<Time
												dateBegin={moment(
													item.dateBegin
												).format('DD.MM.YYYY')}
												timeBegin={moment(
													item.dateBegin
												).format('HH:mm:ss')}
												dateEnd={moment(
													item.dateEnd
												).format('DD.MM.YYYY')}
												timeEnd={moment(
													item.dateEnd
												).format('HH:mm:ss')}
											/>
										</Table.Column>
										<Table.Column>
											<Switch
												id={item.id}
												active={item.active}
												list={games.content}
												onChange={setGames}
												saveList={saveGame}
												customPopUpText={false}
											/>
										</Table.Column>
										<Table.Column className="text-right [&_button]:w-20">
											<Button
												size="xs"
												variant="secondary-outline"
												className="inline-flex"
												onClick={() => {
													setShowEdit({
														id: item.id,
													});
												}}
											>
												Düzenle
											</Button>
										</Table.Column>
									</Table.Row>
								))}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />
			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getGames.loading ||
					api.saveGame.loading ||
					api.removeGame.loading ||
					api.getBrands.loading ||
					api.getBrandStyles.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setCurrentImage([]);
					setChoices([]);
					setTrueChoice(0);
					resetSaveGame();
				}}
			>
				<form onSubmit={handleSubmitSaveGame(saveGame)} noValidate>
					<div className="px-6">
						<Modal.Section title="İçerik">
							<Input
								type="text"
								name="title"
								label="Başlık"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="description"
								label="Açıklama"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="number"
								name="score"
								label="Puan"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="number"
								name="remainTime"
								label="Süre"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateBegin"
								label="Başlangıç Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now()).format(
									'YYYY-MM-DD HH:mm'
								)}
								register={registerSaveGame}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateEnd"
								label="Bitiş Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now())
									.add(1, 'years')
									.format('YYYY-MM-DD HH:mm')}
								register={registerSaveGame}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="active"
								label="Durum"
								grid
								options={[
									{
										name: 'Aktif',
										value: true,
									},
									{
										name: 'Pasif',
										value: false,
									},
								]}
								defaultValue
								register={registerSaveGame}
								errors={errorsSaveGame}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Görünüm">
							<Input
								type="text"
								name="question"
								label="Soru Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="correctWord"
								label="Doğru Cevap Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="buttonText"
								label="Düğme Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="inGameDescription"
								label="Oyun İçi Açıklama"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="backgroundPattern"
								label="Arkaplan Rengi"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								defaultValue="#"
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="numberOfTry"
								label="Cümle Tahmin Hakkı"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								errors={errorsSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="numberOfLetterTry"
								label="Harf Tahmin Hakkı"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="timeoutAnswerText"
								label="Zaman Aşımı Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="trueAnswerText"
								label="Doğru Cevap Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="wrongAnswerText"
								label="Yanlış Cevap Metni"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveGame}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveGame}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Görsel">
							{/* images start */}
							<div className="group">
								<div className="grid grid-cols-3 items-center">
									<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
										Simge
									</label>
									<div className="col-span-2">
										<div className="mb-4 grid grid-cols-4 gap-4">
											{currentImage &&
											currentImage.length ? (
												currentImage.map(
													(image, index) => (
														<div
															className="relative inline-block"
															key={index}
														>
															<img
																src={
																	typeof image ===
																	'object'
																		? window.URL.createObjectURL(
																				image
																			)
																		: image
																}
																key={index}
																alt=""
																className="aspect-square h-full w-full
															rounded-lg object-cover shadow bg-slate-50"
															/>
															<button
																type="button"
																className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																onClick={() =>
																	removeImage(
																		index
																	)
																}
															>
																<BsFillXCircleFill
																	className="translate h-5 w-5
															rounded-full border-2 bg-white border-white text-red-500"
																/>
															</button>
														</div>
													)
												)
											) : (
												<div
													className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
												>
													Görsel Bulunamadı
												</div>
											)}
										</div>
										<div className="relative">
											<input
												type="file"
												accept="image/*"
												onChange={(event) => {
													if (
														event?.target
															?.files?.[0]
													) {
														setCurrentImage([
															...currentImage,
															event.target
																.files[0],
														]);
													}
												}}
												className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
											/>
											{!currentImage.length && (
												<div
													className={`flex h-9 w-full items-center justify-center whitespace-nowrap
                                                        rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
                                                        transition
                                                        duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
                                                    `}
												>
													Yeni Görsel Ekle
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							{/* images end */}
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveGame}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeGame(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveGame).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default GameWordPuzzle;
