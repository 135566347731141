import React from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsFolder } from 'react-icons/bs';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useAxios } from '../../hooks';
import {
	Badge,
	Button,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Table,
} from '../../components';

ChartJS.register(ArcElement);

const Scores = () => {
	const [dashboardItems, setDashboardItems] = React.useState();
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveEmployee,
		// setValue: setValueSaveEmployee,
		handleSubmit: handleSubmitSaveEmployee,
		reset: resetSaveEmployee,
		formState: { errors: errorsSaveEmployee },
	} = useForm();

	const api = {
		getScores: useAxios(),
		saveScore: useAxios(),
	};

	const getScores = () => {
		api.getScores.fetch({
			method: 'GET',
			url: '/score/admin/get/all/score',
		});
	};

	const saveScore = (data) => {
		const body = {
			...data,
		};

		api.saveScore.fetch({
			method: 'POST',
			url: '/employee/updateEmployeeFirm',
			data: body,
		});
	};

	React.useEffect(() => {
		getScores();
	}, []);

	React.useEffect(() => {
		if (api.getScores.response) {
			setDashboardItems([
				{
					id: '1',
					name: 'Toplam',
					value: 0,
					icon: <BsFolder className="h-8 w-8 opacity-25" />,
				},
				{
					id: '3',
					name: 'Harcanan',
					value: 0,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Aktif', 'Pasif'],
								datasets: [
									{
										data: [19, 12],
										backgroundColor: ['#a7f3d0', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
				{
					id: '4',
					name: 'Bekleyen',
					value: 0,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Pasif', 'Aktif'],
								datasets: [
									{
										data: [12, 19],
										backgroundColor: ['#fecdd3', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
			]);
		}
	}, [api.getScores.response]);

	React.useEffect(() => {
		if (api.getScores.error) {
			toast.error(api.getScores.error.message);
		}
	}, [api.getScores.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		// if (showEdit.id && api.getScores.response) {
		// }
	}, [showEdit, api.getScores.response]);

	React.useEffect(() => {
		if (api.saveScore.response) {
			resetSaveEmployee();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getScores();
		}
	}, [api.saveScore.response]);

	React.useEffect(() => {
		if (api.saveScore.error) {
			toast.error(api.saveScore.error.message);
		}
	}, [api.saveScore.error]);

	return (
		<Page>
			<Page.Header header="Puan İşlemleri" title="Puan Hareketleri">
				{/* <Page.Header.Add show onClick={() => setShowEdit(true)} /> */}
			</Page.Header>

			<Page.Body
				loading={api.getScores.loading}
				show={!api.getScores.loading && api.getScores.response}
			>
				<Page.Dashboard items={dashboardItems} />
				<Page.Content>
					<Table className="!table-fixed">
						<Table.Head
							data={['Sıra', 'Üye', 'Bayi', 'Puan', '']}
						/>
						<Table.Body>
							{api.getScores.response?.data?.content?.length &&
								api.getScores.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{index + 1}
												</span>
											</Table.Column>
											<Table.Column>
												<Item
													title={`${item.name} ${item.surname}`}
													description="@akayakagunduz"
													className="gap-3 [&_p]:text-xs"
													{...(item.photo && {
														image: item.photo,
													})}
												/>
											</Table.Column>
											<Table.Column>
												<div className="flex flex-col gap-2 !whitespace-normal text-xs ">
													<span className="font-normal">
														Akay Akagunduz Ltd.
													</span>
													<div className="flex items-center gap-2">
														<Badge className="font-medium">
															PTT:{' '}
															<span className="!font-light text-slate-500">
																123456
															</span>
														</Badge>
													</div>
												</div>
											</Table.Column>
											<Table.Column className="text-base font-medium">
												{item.score}
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
												>
													Görüntüle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={api.getScores.loading || api.saveScore.loading}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveEmployee();
				}}
			>
				<form onSubmit={handleSubmitSaveEmployee(saveScore)} noValidate>
					<div className="px-6">
						<Modal.Section title="Üye">
							<Input
								name="name"
								label="İsim"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="surname"
								label="Soy İsim"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="username"
								label="Kullanıcı Adı"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="İletişim">
							<Input
								name="email"
								label="E-Posta"
								grid
								placeholder="Lütfen giriniz."
								type="email"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
									pattern: {
										value: /^\S+@\S+$/i,
										message: 'Geçerli bir e-posta giriniz.',
									},
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								name="mobileNo"
								label="Telefon"
								grid
								placeholder="Lütfen giriniz."
								type="number"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
									minLength: {
										value: 11,
										message:
											'Bu alan 11 haneden oluşmalıdır.',
									},
									maxLength: {
										value: 11,
										message:
											'Bu alan 11 haneden oluşmalıdır.',
									},
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Bayi">
							<Input
								name="dealerSignName"
								label="Bayi Tabela Adı"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="salesDocumentType"
								label="Satış Belgesi Tipi"
								grid
								options={[
									{
										name: 'Lütfen seçiniz',
										value: '',
									},
									{
										name: 'P',
										value: 'P',
									},
									{
										name: 'PT',
										value: 'Pt',
									},
									{
										name: 'PTT',
										value: 'PTT',
									},
								]}
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							<Input
								name="salesDocumentNumber"
								label="Satış Belgesi No"
								grid
								placeholder="Lütfen giriniz."
								type="text"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Diğer">
							<Input
								name="score"
								label="Puan"
								grid
								placeholder="Lütfen giriniz."
								type="number"
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="userType"
								label="Tip"
								grid
								options={[
									{
										name: 'Lütfen seçiniz',
										value: '',
									},
									{
										name: 'Üye',
										value: 'USER',
									},
									{
										name: 'Yönetici',
										value: 'ADMIN',
									},
								]}
								register={registerSaveEmployee}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveEmployee}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveEmployee}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveEmployee).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default Scores;
