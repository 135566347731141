import React from 'react';
import Button from '../Button';

const Pagination = ({
	currentPage,
	totalPages,
	onPageChange,
	size,
	onPageSizeChange,
}) => {
	const handlePreviousPage = () => {
		if (currentPage > 0) {
			onPageChange(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalPages - 1) {
			onPageChange(currentPage + 1);
		}
	};

	const handlePageSizeChange = (event) => {
		onPageSizeChange(Number(event.target.value));
	};

	return (
		<div className="mt-3 flex flex-col gap-2">
			<div className="flex items-center justify-center">
				<Button
					variant="transparent"
					size="sm"
					onClick={handlePreviousPage}
					disabled={currentPage === 0}
				>
					«
				</Button>
				{[...Array(totalPages).keys()].map((page) => (
					<Button
						key={page}
						variant={
							page === currentPage ? 'primary' : 'transparent'
						}
						size="sm"
						onClick={() => onPageChange(page)}
					>
						{page + 1}
					</Button>
				))}
				<Button
					variant="transparent"
					size="sm"
					onClick={handleNextPage}
					disabled={currentPage === totalPages - 1}
				>
					»
				</Button>
			</div>
			{/* 			<div className="flex justify-end">
				<label htmlFor="page-size">Items per page: </label>
				<select
					id="page-size"
					value={size}
					onChange={handlePageSizeChange}
					className={{
						select:
						'!bg-white !border-slate-500 hover:!text-slate-600 hover:!border-slate-600',
					}}
				>
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="20">20</option>
				</select>
			</div> */}
		</div>
	);
};

export default Pagination;
