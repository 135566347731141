import React from 'react';

import Body from './Body';
import Column from './Column';
import Head from './Head';
import Row from './Row';

const Table = ({ children, className }) => (
	<div className={`overflow-x-auto ${className || ''}`}>
		<table className="w-full table-auto">{children}</table>
	</div>
);

Table.Body = Body;
Table.Column = Column;
Table.Head = Head;
Table.Row = Row;

export default Table;
