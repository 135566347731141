import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { BsFillXCircleFill } from 'react-icons/bs';
import { Button, FormInput, Select } from '../../../components';
import { useAxios } from '../../../hooks';

const EditWhoWantScore = () => {
	const { id } = useParams();
	const [data, setData] = useState({});

	const api = {
		getGames: useAxios(),
		saveGame: useAxios(),
		removeGame: useAxios(),
		getBrandStyles: useAxios(),
	};

	const getGames = () => {
		api.getGames.fetch({
			method: 'GET',
			url: '/game/admin/wwsList?page=0&size=9999',
		});
	};
	useEffect(() => {
		getGames();
	}, []);

	useEffect(() => {
		if (api.getGames.error) {
			toast.error(api.getGames.error.message);
		}
	}, [api.getGames.error]);

	const [gameFormData, setGameFormData] = useState({
		title: 'Test',
		description: 'Açıklamaaaa',
		dateBegin: moment('2024-01-04T09:51:06+03:00').format(
			'YYYY-MM-DDTHH:mm'
		),
		dateEnd: moment('2025-12-04T09:51:06+03:00').format('YYYY-MM-DDTHH:mm'),
		remainTime: 90,
		inGameDescription: 'Açıklamaaaa2',
		active: true,
		buttonText: 'BAŞLA',
		backgroundPattern: '#faa',
		trueAnswerText: '',
		wrongAnswerText: '',
		timeoutAnswerText: '',
		luckyWheelType: '',
		wheelPartDTOList: [],
	});

	const [currentImage, setCurrentImage] = useState([]);
	const [errorsSaveGame, setErrorsSaveGame] = useState({});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setGameFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const removeImage = (index) => {
		setCurrentImage((prev) => prev.filter((_, i) => i !== index));
	};

	const handleSubmitSaveGame = (e) => {
		e.preventDefault();

		const errors = {};
		if (!gameFormData.title) {
			errors.title = 'Başlık alanı zorunludur.';
		}
		if (!gameFormData.description) {
			errors.description = 'Açıklama alanı zorunludur.';
		}
		if (Object.keys(errors).length) {
			setErrorsSaveGame(errors);
			return;
		}

		console.log('Form data:', gameFormData);
	};

	return (
		<div className="container mx-auto p-4 m-4">
			<h1 className="text-xl font-bold mb-4">Edit Who Want Score</h1>
			<form onSubmit={handleSubmitSaveGame} noValidate>
				<div className="grid grid-cols-2 gap-4 px-20 pt-20">
					<FormInput
						grid
						type="text"
						label="Başlık"
						name="title"
						placeholder="Lütfen giriniz."
						value={gameFormData.title}
						onChange={handleChange}
						errors={errorsSaveGame.title}
					/>
					<FormInput
						grid
						type="text"
						label="Açıklama"
						name="description"
						placeholder="Lütfen giriniz."
						value={gameFormData.description}
						onChange={handleChange}
						errors={errorsSaveGame.description}
					/>
					<FormInput
						grid
						type="datetime-local"
						label="Başlangıç Tarihi"
						name="dateBegin"
						value={gameFormData.dateBegin}
						onChange={handleChange}
					/>
					<FormInput
						grid
						type="datetime-local"
						label="Bitiş Tarihi"
						name="dateEnd"
						value={gameFormData.dateEnd}
						onChange={handleChange}
					/>
					<FormInput
						grid
						type="number"
						label="Kalan Süre"
						name="remainTime"
						placeholder="Lütfen giriniz."
						value={gameFormData.remainTime}
						onChange={handleChange}
					/>
					<FormInput
						grid
						type="text"
						label="Oyun İçi Açıklama"
						name="inGameDescription"
						placeholder="Lütfen giriniz."
						value={gameFormData.inGameDescription}
						onChange={handleChange}
					/>
					<FormInput
						grid
						type="text"
						label="Buton Yazısı"
						name="buttonText"
						placeholder="Lütfen giriniz."
						value={gameFormData.buttonText}
						onChange={handleChange}
					/>
					<FormInput
						grid
						type="text"
						label="Arkaplan Deseni"
						name="backgroundPattern"
						placeholder="Lütfen giriniz."
						value={gameFormData.backgroundPattern}
						onChange={handleChange}
					/>
				</div>

				<div className="mb-4 px-20 pt-6">
					<Select
						grid
						label="Durum"
						name="active"
						options={[
							{ name: 'Aktif', value: true },
							{ name: 'Pasif', value: false },
						]}
						value={gameFormData.active}
						onChange={handleChange}
					/>
				</div>

				<div className="mb-4 px-20 pt-6">
					<div className="group">
						<div className="grid grid-cols-3 items-center">
							<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
								Simge
							</label>
							<div className="col-span-2">
								<div className="mb-4 grid grid-cols-4 gap-4">
									{currentImage && currentImage.length ? (
										currentImage.map((image, index) => (
											<div
												className="relative inline-block"
												key={index}
											>
												<img
													src={
														typeof image ===
														'object'
															? window.URL.createObjectURL(
																	image
																)
															: image
													}
													alt=""
													className="aspect-square h-full w-full rounded-lg object-cover shadow bg-slate-50"
												/>
												<button
													type="button"
													className="absolute right-0 top-0 -translate-y-2 translate-x-2"
													onClick={() =>
														removeImage(index)
													}
												>
													<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
												</button>
											</div>
										))
									) : (
										<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
											Görsel Bulunamadı
										</div>
									)}
								</div>
								<div className="relative">
									<input
										type="file"
										accept="image/*"
										onChange={(event) => {
											if (event?.target?.files?.[0]) {
												setCurrentImage((prev) => [
													...prev,
													event.target.files[0],
												]);
											}
										}}
										className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
									/>
									<button
										type="button"
										className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600"
										onClick={() =>
											document
												.querySelector(
													'input[type="file"]'
												)
												.click()
										}
									>
										Yeni Görsel Ekle
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex justify-end mt-4 px-20 pt-6">
					<Button type="submit" variant="primary">
						Kaydet
					</Button>
				</div>
			</form>
		</div>
	);
};

export default EditWhoWantScore;
