/* eslint-disable */
import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	BsFolder,
	BsClock,
	BsTruck,
	BsBox,
	BsCheckSquare,
	BsBackspace,
} from 'react-icons/bs';

import { useAxios } from '../../hooks';
import {
	Badge,
	Button,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Table,
	Thumbnails,
	Time,
	Pagination,
} from '../../components';

const Orders = () => {
	const [dashboardItems, setDashboardItems] = React.useState();
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();

	// State for pagination
	const [page, setPageNumber] = React.useState(0);
	const [size, setPageSize] = React.useState(20);
	const [totalPages, setTotalPages] = React.useState(1);

	const {
		register: registerSaveOrder,
		setValue: setValueSaveOrder,
		handleSubmit: handleSubmitSaveOrder,
		reset: resetSaveOrder,
		formState: { errors: errorsSaveOrder },
	} = useForm();

	const api = {
		getDashboard: useAxios(),
		getOrders: useAxios(),
		saveOrder: useAxios(),
		cancelOrder: useAxios(),
	};

	const getDashboard = () => {
		api.getDashboard.fetch({
			method: 'GET',
			url: '/order/admin/dashboard',
		});
	};

	const getOrders = () => {
		api.getOrders.fetch({
			method: 'GET',
			url: '/order/admin/orderList',
			params: {
				page,
				size,
			},
		});
	};

	React.useEffect(() => {
		getDashboard();
		getOrders();
	}, [page, size]);

	// React.useEffect(() => {
	// 	if (api.getOrders.response) {
	// 		console.log(api.getOrders.response);
	// 	}
	// }, [api.getOrders.response]);

	React.useEffect(() => {
		if (api.getOrders.response) {
			setTotalPages(api.getOrders.response.data.totalPages);
			console.log(api.getOrders.response);
		}
	}, [api.getOrders.response]);

	React.useEffect(() => {
		if (api.getDashboard.response) {
			setDashboardItems([
				// Dashboard item setup
			]);
		}
	}, [api.getDashboard.response]);

	const saveOrder = (data) => {
		const { orderID, orderStatu } = data;

		const body = {
			orderID,
			orderStatu,
		};

		api.saveOrder.fetch({
			method: 'POST',
			url: '/order/admin/change/order/statu',
			data: body,
		});
	};

	const cancelOrder = (id) => {
		api.saveOrder.fetch({
			method: 'POST',
			url: `/order/admin/order/cancel/${id}`,
		});
	};

	React.useEffect(() => {
		if (api.getDashboard.response) {
			setDashboardItems([
				{
					id: 'toplam',
					name: 'Toplam',
					value: api.getDashboard.response.data.orderSize,
					icon: <BsFolder className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'hazirlaniyor',
					name: 'Hazırlanıyor',
					value: api.getDashboard.response.data.preparingOrderSize,
					icon: <BsClock className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'kargoda',
					name: 'Kargoda',
					value: api.getDashboard.response.data.orderInCargoSize,
					icon: <BsTruck className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'dagitimda',
					name: 'Dağıtımda',
					value: api.getDashboard.response.data
						.outForDeliverOrderSize,
					icon: <BsBox className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'teslim-edildi',
					name: 'Teslim Edildi',
					value: api.getDashboard.response.data.deliveredOrderSize,
					icon: <BsCheckSquare className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'iade',
					name: 'İade',
					value: api.getDashboard.response.data.returnedOrderSize,
					icon: <BsBackspace className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'iptal',
					name: 'İptal',
					value: api.getDashboard.response.data.canceledOrderSize,
				},
			]);
		}
	}, [api.getDashboard.response]);

	React.useEffect(() => {
		if (api.getOrders.error) {
			toast.error(api.getOrders.error.message);
		}
	}, [api.getOrders.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getOrders.response) {
			const { id } = showEdit;
			const item =
				api.getOrders.response.data.content[
					api.getOrders.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			setValueSaveOrder('name', item.name);
			setValueSaveOrder('code', item.code);
			setValueSaveOrder(
				'dateBegin',
				moment(item.dateBegin).format('YYYY-MM-DD HH:mm:ss')
			);
			setValueSaveOrder(
				'dateEnd',
				moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss')
			);
		}
	}, [showEdit, api.getOrders.response]);

	React.useEffect(() => {
		if (api.saveOrder.response) {
			resetSaveOrder();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getOrders();
		}
	}, [api.saveOrder.response]);

	React.useEffect(() => {
		if (api.saveOrder.error) {
			toast.error(api.saveOrder.error.message);
		}
	}, [api.saveOrder.error]);

	console.log(api.getOrders.response);

	return (
		<Page>
			<Page.Header
				header="Siparişler"
				title="Tüm Siparişler"
			></Page.Header>

			<Page.Body
				loading={api.getOrders.loading}
				show={!api.getOrders.loading && api.getOrders.response}
			>
				<Page.Dashboard items={dashboardItems} />
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Ürünler',
								'Puan',
								'Fiyat (TL)',
								'Tür',
								'Adres',
								'Açıklama',
								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{api.getOrders.response?.data?.content?.length &&
								api.getOrders.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												{item.orderProductDTOList && (
													<Thumbnails
														max={5}
														images={[
															...item.orderProductDTOList.map(
																(product) => ({
																	url: product
																		?.productDTO
																		?.productContentUrlList
																		?.productContentUrlList?.[0]
																		?.baseAddress
																		? product
																				?.productDTO
																				?.productContentUrlList?.[0]
																				.baseAddress
																		: require('../../assets/logo-connection.png'),
																	name: product
																		.productDTO
																		.name,
																})
															),
														]}
													/>
												)}
											</Table.Column>
											<Table.Column className="text-base font-medium">
												{item.score}
											</Table.Column>
											<Table.Column className="text-base font-medium">
												{item.paidPrice}
											</Table.Column>
											<Table.Column>
												<Badge>
													{item.categoryType
														.toLowerCase()
														.replace(/^\w/, (c) =>
															c.toUpperCase()
														)}
												</Badge>
											</Table.Column>
											<Table.Column className="w-48 !whitespace-normal text-xs capitalize text-slate-400">
												<h6 className="mb-1 font-normal">
													{item.fullName}
												</h6>
												<p>{item.deliveryAddress}</p>
											</Table.Column>
											<Table.Column className="w-48 !whitespace-normal text-xs text-slate-400">
												{item.description}
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateOrder
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateOrder
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												<Badge
													variant={
														{
															'SİPARİŞ ALINDI':
																'default',
															'DAĞITIMA ÇIKARILDI':
																'info',
															'SİPARİŞİNİZ HAZIRLANIYOR':
																'default',
															'KARGOYA TESLİM EDİLDİ':
																'warning',
															'TESLİM EDİLDİ':
																'success',
															'İPTAL EDİLDİ':
																'danger',
															'İADE EDİLDİ':
																'danger',
														}[item.orderStatu]
													}
												>
													{
														{
															'SİPARİŞ ALINDI':
																'Alındı',
															'DAĞITIMA ÇIKARILDI':
																'Dağıtımda',
															'SİPARİŞİNİZ HAZIRLANIYOR':
																'Hazırlanıyor',
															'KARGOYA TESLİM EDİLDİ':
																'Kargoda',
															'TESLİM EDİLDİ':
																'Teslim Edildi',
															'İPTAL EDİLDİ':
																'İptal',
															'İADE EDİLDİ':
																'İade',
														}[item.orderStatu]
													}
												</Badge>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
					<Pagination
						currentPage={page}
						totalPages={totalPages}
						onPageChange={(newPage) => setPageNumber(newPage)}
						pageSize={size}
						onPageSizeChange={(newSize) => setPageSize(newSize)}
					/>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={api.getOrders.loading || api.saveOrder.loading}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveOrder();
				}}
			>
				<form onSubmit={handleSubmitSaveOrder(saveOrder)} noValidate>
					<div className="flex flex-col gap-4 p-6">
						{showEdit.id && (
							<Table className="mb-4 [&_td]:p-3">
								<Table.Head
									data={[
										'Ürün',
										'Adet',
										'Puan',
										'Fiyat',
										'Stok',
									]}
								/>
								<Table.Body>
									{api.getOrders.response?.data?.content
										?.length &&
										api.getOrders.response.data.content[
											api.getOrders.response.data.content.findIndex(
												(item) =>
													item.id === showEdit.id
											)
										].orderProductDTOList.map(
											(item, index) => (
												<Table.Row key={index}>
													<Table.Column>
														<Item
															image={
																item.productDTO &&
																item.productDTO
																	.productContentUrlList &&
																item.productDTO
																	.productContentUrlList[0] &&
																item.productDTO
																	.productContentUrlList[0]
																	.baseAddress
																	? item
																			.productDTO
																			.productContentUrlList[0]
																			.baseAddress
																	: require('../../assets/logo-connection.png')
															}
															title={
																item.productDTO
																	.name
															}
															size="sm"
															className="[&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
														/>
													</Table.Column>
													<Table.Column>
														<div className="inline-flex items-center justify-center gap-2">
															<span className="text-xs text-slate-400">
																x
															</span>
															{item.productCount}
														</div>
													</Table.Column>
													<Table.Column>
														{item.productDTO.score}
													</Table.Column>
													<Table.Column>
														{item.paidPrice}
													</Table.Column>
													<Table.Column>
														{item.productDTO.stock}
													</Table.Column>
												</Table.Row>
											)
										)}
								</Table.Body>
							</Table>
						)}
						<Select
							name="orderStatu"
							label="Durum"
							grid
							options={[
								{
									name: 'Sipariş Alındı',
									value: 'ORDERED',
								},
								{
									name: 'Siparişiniz Hazırlanıyor',
									value: 'PREPARING',
								},
								{
									name: 'İptal Edildi',
									value: 'CANCELED',
								},
								{
									name: 'Kargoya Teslim Edildi',
									value: 'DELIVEREDTOCARGO',
								},
								{
									name: 'Dağıtıma Çıkarıldı',
									value: 'OUTFORDELIVERED',
								},
								{
									name: 'Teslim Edildi',
									value: 'DELIVERED',
								},
								{
									name: 'İade Edildi',
									value: 'RETURNED',
								},
							]}
							defaultValue={0}
							register={registerSaveOrder}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveOrder}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					<div>
						{showEdit.id && (
							<Input
								type="hidden"
								name="orderID"
								register={registerSaveOrder}
								defaultValue={
									showEdit.subId
										? showEdit.subId
										: showEdit.id
								}
							/>
						)}
					</div>

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								onClick={() => cancelOrder(showEdit.id)}
								className="w-32"
							>
								İptal Et
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveOrder).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default Orders;
