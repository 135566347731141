import React from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useAxios } from '../../hooks';
import { Button, Input, Modal, Page, Table } from '../../components';

const ForumBadWords = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveForumBadWords,
		handleSubmit: handleSubmitSaveForumBadWords,
		reset: resetSaveForumBadWords,
		formState: { errors: errorsSaveForumBadWords },
	} = useForm();

	const api = {
		getForumBadWords: useAxios(),
		saveForumBadWords: useAxios(),
		removeForumBadWords: useAxios(),
	};

	const getForumBadWords = () => {
		api.getForumBadWords.fetch({
			method: 'GET',
			url: '/forum/get/all/bad/words?page=0&size=9999',
		});
	};

	const saveForumBadWords = (data) => {
		api.saveForumBadWords.fetch({
			method: 'POST',
			url: '/forum/admin/save/bad/word',
			data: {
				word: data.word,
			},
		});
	};

	React.useEffect(() => {
		getForumBadWords();
	}, []);

	React.useEffect(() => {
		if (api.getForumBadWords.error) {
			toast.error(api.getForumBadWords.error.message);
		}
	}, [api.getForumBadWords.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (api.saveForumBadWords.response) {
			resetSaveForumBadWords();
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getForumBadWords();
		}
	}, [api.saveForumBadWords.response]);

	React.useEffect(() => {
		if (api.saveForumBadWords.error) {
			toast.error(api.saveForumBadWords.error.message);
		}
	}, [api.saveForumBadWords.error]);

	const removeForumBadWords = (id) => {
		api.removeForumBadWords.fetch({
			method: 'POST',
			url: '/forum/admin/delete/bad/word',
			data: {
				badWordId: id,
			},
		});
	};

	React.useEffect(() => {
		if (api.removeForumBadWords.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getForumBadWords();
		}
	}, [api.removeForumBadWords.response]);

	React.useEffect(() => {
		if (api.removeForumBadWords.error) {
			toast.error(api.removeForumBadWords.error.message);
		}
	}, [api.removeForumBadWords.error]);

	return (
		<Page>
			<Page.Header header="Forum" title="Filtreli Kelimeler">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getForumBadWords.loading}
				show={
					!api.getForumBadWords.loading &&
					api.getForumBadWords.response
				}
			>
				<Page.Content>
					<Table className="!table-fixed">
						<Table.Head data={['Id', 'Kelime', '']} />
						<Table.Body>
							{api.getForumBadWords.response?.data?.content
								?.length &&
								api.getForumBadWords.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column className="w-4">
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<div className="grid gap-1">
													<h6 className="truncate whitespace-nowrap text-sm font-normal capitalize text-slate-700">
														{item.word}
													</h6>
												</div>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<div className="flex flex-col items-end justify-center gap-2">
													<Button
														size="xs"
														variant="danger-outline"
														className="inline-flex"
														onClick={() =>
															removeForumBadWords(
																item.id
															)
														}
													>
														Sil
													</Button>
												</div>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getForumBadWords.loading ||
					api.saveForumBadWords.loading ||
					api.removeForumBadWords.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					resetSaveForumBadWords();
				}}
			>
				<form
					onSubmit={handleSubmitSaveForumBadWords(saveForumBadWords)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						<Input
							type="text"
							name="word"
							label="Kelime"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveForumBadWords}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveForumBadWords}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
					</div>

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveForumBadWords).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default ForumBadWords;
