import React from 'react';
import { Switch } from '@headlessui/react';
import { Helmet } from 'react-helmet';
import nightwind from 'nightwind/helper';

const Footer = () => {
	const [darkMode, setDarkMode] = React.useState(
		localStorage.getItem('nightwind-mode') === 'dark'
	);

	React.useEffect(() => {
		nightwind.enable(darkMode);
	}, [darkMode]);

	return (
		<>
			<Helmet>
				<script>{nightwind.init()}</script>
			</Helmet>
			<div className="grid grid-cols-1 items-center gap-4 lg:grid-cols-2">
				<p className="text-xs text-slate-400 max-lg:text-center">
					© {new Date().getFullYear()}{' '}
					<a
						href="https://www.jarvis.com.tr/"
						target="_blank"
						rel="noreferrer"
						className="font-normal hover:underline"
					>
						Jarvis Yazılım A.Ş.
					</a>{' '}
				</p>
				<div className="flex items-center justify-center gap-12 lg:justify-end">
					<ul className="flex flex-wrap  gap-x-4 gap-y-2 text-sm">
						{[
							{
								name: 'Çerez Politikası',
								url: '#',
							},
							{
								name: 'KVKK Metni',
								url: '#',
							},
							{
								name: 'Kullanım Koşulları',
								url: '#',
							},
						].map((item, index) => (
							<li key={index}>
								<a
									href={item.url}
									target="_blank"
									className="text-blue-700 hover:underline"
									rel="noreferrer"
								>
									{item.name}
								</a>
							</li>
						))}
					</ul>
					<div className="flex items-center gap-2">
						<span className="text-xs text-slate-500">Koyu mod</span>
						<Switch
							defaultChecked={darkMode}
							as={React.Fragment}
							onChange={() => setDarkMode(!darkMode)}
						>
							{({ checked }) => (
								<button
									type="button"
									className={`${
										checked ? 'bg-green-500' : 'bg-gray-400'
									} relative ml-auto inline-flex h-6 w-11 items-center rounded-full`}
								>
									<span
										className={`${
											checked
												? 'translate-x-6'
												: 'translate-x-1'
										} inline-block h-4 w-4 transform rounded-full transition duration-300 bg-white`}
									/>
								</button>
							)}
						</Switch>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
