import React from 'react';

import toast from 'react-hot-toast';
import Select from '../Select';
import Body from './Body';
import Content from './Content';
import Dashboard from './Dashboard';
import Footer from './Footer';
import Header from './Header';

import Context from '../Context';

import { useAxios } from '../../hooks';

const Page = ({ children, className }) => {
	const [context] = React.useContext(Context);
	const [tenantData, setTenantData] = React.useState([
		{ name: 'Lütfen bir seçenek seçiniz', value: '' },
	]);

	const [selectedTenant, setSelectedTenant] = React.useState('');

	const api = {
		getTenants: useAxios(),
	};

	const getTenants = () => {
		api.getTenants.fetch({
			method: 'GET',
			url: '/auth/superadmin/get/tenants',
		});
	};

	const onChange = (event) => {
		event.preventDefault();

		if (event.target.value) {
			setSelectedTenant(event.target.value);
			setTimeout(() => window.location.reload(), 500);
		}
	};

	React.useEffect(() => {
		if (selectedTenant) {
			localStorage.setItem('tenant', selectedTenant);
		}
	}, [selectedTenant]);

	React.useEffect(() => {
		if (localStorage.getItem('tenant')) {
			setSelectedTenant(localStorage.getItem('tenant'));
		}
	}, []);

	React.useEffect(() => {
		if (context?.signed?.data?.employeeFirm?.role === 'SUPERADMIN') {
			// GET TENANTS
			getTenants();
		}
	}, []);

	React.useEffect(() => {
		// ADD TENANTS FOR SELECTBOX
		if (api.getTenants.response) {
			if (api.getTenants.response?.data?.length > 0) {
				setTenantData((prevTenantData) => [
					...prevTenantData,
					...api.getTenants.response.data.map((item) => ({
						name: `${item.name} - ${item.hostName}`,
						value: item.name,
					})),
				]);
			}
		}
	}, [api.getTenants.response]);

	React.useEffect(() => {
		if (api.getTenants.error) {
			toast.error(api.getTenants.error.message);
		}
	}, [api.getTenants.error]);

	return (
		<main
			className={`mx-auto flex w-full grow flex-col gap-8 overflow-y-auto p-4 lg:container lg:p-8 ${
				className || ''
			}`}
		>
			{context?.signed?.data?.employeeFirm?.role === 'SUPERADMIN' && (
				<form noValidate className="md:ml-auto">
					<div className="flex flex-col">
						{tenantData?.length > 0 && (
							<Select
								name="tenant"
								label="Tenant"
								grid
								options={tenantData}
								value={selectedTenant}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								size="lg"
								className={{ select: '!text-sm' }}
								onChange={(event) => onChange(event)}
							/>
						)}
					</div>
				</form>
			)}

			{children}
		</main>
	);
};

Page.Body = Body;
Page.Content = Content;
Page.Dashboard = Dashboard;
Page.Footer = Footer;
Page.Header = Header;

export default Page;
