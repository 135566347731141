import React from 'react';
import { BsFillXCircleFill } from 'react-icons/bs';
import { FormInput, Modal } from '../../components';

const GameLuckyWheelQuestions = ({
	wheelPartList,
	errorsSaveGame,
	handleRemoveCategory,
	handleAddCategory,
	handleChange,
	partTitle,
}) => {
	// console.log(wheelPartList);
	return (
		<Modal.Section title={partTitle}>
			{wheelPartList?.map((question) => (
				<div
					className="relative pr-8 rounded-lg border-2 p-4"
					key={question.id}
				>
					<FormInput
						id={`text-${question.id}`}
						grid
						type="text"
						name="text"
						label="Kategori Adı"
						value={question.text || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`color-${question.id}`}
						grid
						type="text"
						name="color"
						label="Renk Kodu"
						value={question.color || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`percentage-${question.id}`}
						grid
						type="number"
						name="percentage"
						label="Yüzde"
						value={question.percentage || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`score-${question.id}`}
						grid
						type="number"
						name="score"
						label="Puan"
						value={question.score || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`selectionLimit-${question.id}`}
						grid
						type="number"
						name="selectionLimit"
						label="Seçim Limiti"
						value={question.selectionLimit || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<FormInput
						id={`textColor-${question.id}`}
						grid
						type="text"
						name="textColor"
						label="Yazı Rengi"
						value={question.textColor || ''}
						onChange={(e) => handleChange(e, question.id)}
						validation={{
							required: 'Bu alan zorunludur.',
						}}
						errors={errorsSaveGame}
						size="lg"
						className={{ input: '!text-sm my-2' }}
					/>

					<button
						className="absolute right-0 top-3.5"
						type="button"
						onClick={() => handleRemoveCategory(question.id)}
					>
						<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
					</button>
				</div>
			))}

			<button
				className="flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600"
				type="button"
				onClick={handleAddCategory}
			>
				Yeni Kategori Ekle
			</button>
		</Modal.Section>
	);
};

export default GameLuckyWheelQuestions;
